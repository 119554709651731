import { CreateWorkerReq, UpdateWorkerReq } from '../../../../services/sms';
import { WorkerStore } from './worker.store';
import { SMSService } from '../../../../services/sms/service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WorkerService {

  constructor(private store: WorkerStore, protected smsService: SMSService) {
  }

  public async workers() {
    const r = await this.smsService.worker.workerListAll();
    const workers = r.data.Message;
    this.store.set(workers);
    return workers;
  }

  public async projectWorkers(projectID: number) {
    const r = await this.smsService.project.projectWorkers(projectID);
    const workers = r.data.Message;
    this.store.set(workers);
    return workers;
  }

  public async createWorker(SubcontractorID: number, body: CreateWorkerReq) {
    const r = await this.smsService.worker.workerCreate(SubcontractorID, body);
    const worker = r.data.Message;
    this.store.add(worker);
    return worker;
  }

  public async updateWorker(workerID: number, body: UpdateWorkerReq) {
    const r = await this.smsService.worker.workerUpdate(workerID, body);
    const worker = r.data.Message;
    this.store.update(worker.ID, worker);
    return worker;
  }

  public async removeWorker(workerID: number) {
    await this.smsService.worker.workerRemove(workerID);
    this.store.remove(workerID);
  }

  public async workerInfo(workerID: number) {
    const workerRes = await this.smsService.worker.workerInfo(workerID);
    const newWorker = workerRes.data.Message;

    newWorker.Documents = await this.workerDocuments(workerID);

    this.store.update(newWorker.ID, newWorker);
    return newWorker;
  }

  public async workerInfoNoDocuments(workerID: number) {
    const workerRes = await this.smsService.worker.workerInfo(workerID);
    const newWorker = workerRes.data.Message;
    this.store.update(newWorker.ID, newWorker);
    return newWorker;
  }

  public async updateWorkerPositions(workerID: number, IDs: number[]) {
    const r = await this.smsService.worker.workerSetProjectsPosition(workerID, { IDs });
    const newWorker = r.data.Message;

    this.store.update(newWorker.ID, newWorker);
    return newWorker;
  }

  public async updateWorkerProjectPosition(workerID: number, projectID: number, IDs: number[]) {
    const r = await this.smsService.worker.workerSetPosition(workerID, projectID, { IDs });
    const newWorker = r.data.Message;

    this.store.update(newWorker.ID, newWorker);
    return newWorker;
  }

  public async addDocument(workerID: number, documentTypeID: number, expireDate: string, description: string, files: File[]) {
    const r = await this.smsService.worker.workerAddDocument(workerID, documentTypeID, expireDate, description, ...files);
    const newWorker = r.data.Message;

    this.store.update(newWorker.ID, newWorker);
    return newWorker;
  }

  public async AddAditionalDocuments(workerID: number, description: string, files: File[]) {
    const r = await this.smsService.worker.workerAddAditional(workerID, undefined, description, ...files);
    const newWorker = r.data.Message;

    this.store.update(newWorker.ID, newWorker);
    return newWorker;
  }

  public async removeDocument(workerID: number, documentID: number) {
    const r = await this.smsService.worker.workerDocumentRemove(workerID, documentID);
    const newWorker = r.data.Message;

    this.store.update(newWorker.ID, newWorker);
    return newWorker;
  }

  public async workerDocuments(workerID: number) {
    const r = await this.smsService.worker.workerDocuments(workerID);
    return r.data.Message;
  }

  public async setDocumentStatus(workerID: number, documentID: number, projectID: number, NewStatus: number, RejectReason: string) {
    await this.smsService.worker.workerDocumentSetStatus(workerID, documentID, projectID, { NewStatus, RejectReason });
  }

  public async allCanHaveWorkerProjectPositions(workerID: number) {
    const res = await this.smsService.worker.workerProjectsPositions(workerID);
    return res.data.Message;
  }

  public getDocumentFile(workerID: number, fileID: number) {
    return this.smsService.worker.workerDocumentFile(workerID, fileID, { responseType: 'blob' });
  }
}
