/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-error.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./form-error.component";
var styles_FormErrorComponent = [i0.styles];
var RenderType_FormErrorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormErrorComponent, data: {} });
export { RenderType_FormErrorComponent as RenderType_FormErrorComponent };
function View_FormErrorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "error"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
export function View_FormErrorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "invalid-feedback display-block"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormErrorComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errs; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_FormErrorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "form-error", [], null, null, null, View_FormErrorComponent_0, RenderType_FormErrorComponent)), i1.ɵdid(1, 49152, null, 0, i3.FormErrorComponent, [], null, null)], null, null); }
var FormErrorComponentNgFactory = i1.ɵccf("form-error", i3.FormErrorComponent, View_FormErrorComponent_Host_0, { errs: "errs" }, {}, []);
export { FormErrorComponentNgFactory as FormErrorComponentNgFactory };
