import { _ } from '../../util/i18n';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { NotificationService } from '../notification.service';

const bindErrs = (errorKey: string, bindto: any, defaultErrList: string[], errorsToBind: string[]) => {
  let currentSelection = bindto;

  const status = errorKey.split('.').every((path, i, arr) => {
    if (currentSelection === undefined || currentSelection === null) {
      return false;
    }

    if (i === arr.length - 1) {
      currentSelection = currentSelection[path + 'Err'];
    } else {
      currentSelection = currentSelection[path];
    }

    return currentSelection !== undefined && currentSelection !== null;
  });


  if (status && currentSelection !== undefined && currentSelection !== null) {
    currentSelection.push(...errorsToBind);
  } else {
    defaultErrList.push(...errorsToBind);
  }
};

@Injectable({
  providedIn: 'root'
})
export class APIErrorService {
  private errors = {
    INVALID_EMAIL: _('Invalid email address'),
    INVALID_PASSWORD: _('Password does not match requirements'),
    INVALID_LANG: _('Invalid language'),
    INVALID_TYPE: _('Invalid type'),
    INVALID_DATE: _('Invalid date'),
    DATE_TOO_EARLY: _('Date too early'),
    DATE_TOO_LATE: _('Date too late'),
    TOO_SHORT: _('Value too short'),
    TOO_LONG: _('Value too long'),
    VALUES_NO_MATCH: _('Passwords do not match'),
    'User already registered': _('Email already used'),
    INVALID_KEY: _('Invalid Key'),
    INPUT_EMPTY: _('Value can\'t be empty'),
    'Email already used': _('Email address is already used'),
    ORG_ALREADY_EXSIST: _('Company already exists'),
    'Name already used': _('Name already used'),
    'email or password incorect': _('Email or password incorect'),
    VALUE_REQUIRED: _('Value is required'),
    'Project already exsists': _('The project already exists'),
    UserAlreadyValidated: _('The user is already validated'),
    UserEmailValidateKeyMismatch: _('The given validation key does not match the key of the user'),
    WRONG_PASSWORD: _('The password that you entered is incorrect'),
    DUPLICATED_ENTRIES: _('Data submitted already exists'),
    DOCUMENT_TYPE_ALREADY_EXISTS: _('There is already a document with this name'),
    WORKER_ALREADY_HAS_DOCUMENT_TYPE: _('Worker already has this document'),
    'worker already exsists': _('Worker already exists'),
    'account not found': _('Account not found'),
    INTERNAL_BROWSER_ERROR: _('Unknown browser error'),
    'account disabled': _('Your account is disabled'),
    CANT_DEL_DOC_TYPE_WITH_DOCS: _('Document type still in use, can\'t remove it'),
    FILE_TO_LARGE: _('A file you are trying to upload is to big'),
    DESCRIPTION_REQUIRED: _('Description is required'),
    INVALID_APPROVE_STATUS: _('Invalid document approve status'),
    // "": _(''),
    // "": _(''),
    // "": _(''),
    // "": _(''),
    // "": _(''),
    // "": _(''),
    // "": _(''),
    // "": _(''),
    // "": _(''),
    // "": _(''),
    // "": _(''),
    undefined: _('Unknown error'),
  };

  constructor(public translate: TranslateService, protected notificationService: NotificationService) {
  }

  getErr(key: string): string {
    let humanError = this.errors[key];
    if (humanError === undefined || humanError === null) {
      humanError = this.errors.undefined;
    }

    return this.translate.instant(humanError);
  }

  notifyAPIError(data) {
    if (typeof data.response !== 'object' && typeof data.response.data !== 'object') {
      this.notificationService.error(this.translate.instant(_('Something went wrong, please try again.')));
      return;
    }

    if (data.response.data.ErrorCode && this.errors[data.response.data.ErrorCode]) {
      this.notificationService.error(this.translate.instant(this.errors[data.response.data.ErrorCode]));
      return;
    }

    if (data.response.data.ErrorMessage && data.response.data.ErrorMessage !== '') {
      this.notificationService.error(data.response.data.ErrorMessage);
      return;
    }

    this.notificationService.error(this.translate.instant(_('Something went wrong, please try again.')));
    return;
  }

  async tryAndReport(func: () => Promise<any>, thisBinding: any, bindToThisDefualtVal: string): Promise<Map<string, string[]>> {
    let output = {} as Map<string, string[]>;
    try {
      await func();
      output = this.handleAPIError({}, thisBinding, bindToThisDefualtVal);
    } catch (error) {
      output = this.handleAPIError(error, thisBinding, bindToThisDefualtVal);
    }
    return output;
  }

  handleAPIError(err: any, bindToThis?: any, bindToThisDefualtVal?: string): Map<string, string[]> {
    let output = {} as Map<string, string[]>;

    switch (typeof err) {
      case 'string':
        const undefinedOutput = 'undefined';
        output[undefinedOutput] = [this.getErr(err)];
        break;
      case 'object':
        let loopOver = err;
        if (err.response && err.response.data && typeof err.response.data.ErrorMessage !== undefined) {
          loopOver = err.response.data.ErrorMessage;
        }

        if (typeof loopOver === 'string') {
          return this.handleAPIError(loopOver, bindToThis, bindToThisDefualtVal);
        }

        if ('message' in loopOver && 'stack' in loopOver && typeof loopOver.message === 'string') {
          console.log('javascript error:', err);
          const undefinedOutput = 'undefined';
          output[undefinedOutput] = [this.getErr('INTERNAL_BROWSER_ERROR')];
        } else {
          output = Object.keys(loopOver).reduce((acc, key) => {
            const value = loopOver[key];

            acc[key] = Array.isArray(value)
              ? value.map(item => this.getErr(item))
              : [this.getErr(value)];

            return acc;
          }, {} as Map<string, string[]>);
        }

        break;
      default:
        console.log('Unknown error type:');
        console.dir(err);
    }

    // Filter out the duplicates
    Object.keys(output).map(errorKey => {
      output[errorKey] = output[errorKey].filter((item, pos) => {
        return output[errorKey].indexOf(item) === pos;
      });
    });

    // Bind the errors directly to the component
    if (bindToThis && bindToThisDefualtVal) {
      bindToThis[bindToThisDefualtVal] = [];

      // Empty all form errors
      Object.keys(bindToThis).map(key => {

        if (bindToThis[key] instanceof Object && !bindToThis[key]) {
          Object.keys(bindToThis[key]).map(subKey => {
            if (/.+Err$/.test(subKey)) {
              bindToThis[key][subKey] = [];
            }
          });
        }

        if (/.+Err$/.test(key) && bindToThis[key] instanceof Array) {
          bindToThis[key] = [];
        }

      });

      // Add the errors
      Object.keys(output).map(key => {
        bindErrs(key, bindToThis, bindToThis[bindToThisDefualtVal], output[key]);
      });
    }

    return output;
  }
}
