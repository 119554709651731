import * as tslib_1 from "tslib";
import { EntityStore, StoreConfig } from '@datorama/akita';
import * as i0 from "@angular/core";
export var initialState = {
    active: null,
};
var WorkerStore = /** @class */ (function (_super) {
    tslib_1.__extends(WorkerStore, _super);
    function WorkerStore() {
        return _super.call(this, initialState) || this;
    }
    WorkerStore.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WorkerStore_Factory() { return new WorkerStore(); }, token: WorkerStore, providedIn: "root" });
    WorkerStore = tslib_1.__decorate([
        StoreConfig({
            name: 'worker',
            idKey: 'ID',
            resettable: true,
        })
    ], WorkerStore);
    return WorkerStore;
}(EntityStore));
export { WorkerStore };
