import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserQuery } from '../user/state/user.query';
import { first } from 'rxjs/operators';
import { UserService } from '../user/state/user.service';
import { SMSService } from '../../services/sms/service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuard implements CanActivate, CanActivateChild {

  constructor(
    protected userQuery: UserQuery,
    protected userService: UserService,
    protected router: Router,
    protected smsService: SMSService
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise((res => {
      this.userQuery.isAuthenticated$.pipe(first()).subscribe(isAuthenticated => {
        if (!isAuthenticated) {
          if (this.smsService.tokenStorage.getKey()) {
            this.userService.userMe().then(user => {
              res(true);
            }).catch(e => {
              this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
              res(false);
            });
          } else {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            res(false);
          }
        } else {
          /* Authenticated */
          res(true);
        }
      });
    }));
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }
}
