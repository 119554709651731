<div *ngIf="status == 1" class="d-flex flex-row align-items-center m-2">
  <span class="badge badge-primary mr-md-1" translate>Approved</span>
</div>
<div *ngIf="status == 2" class="d-flex flex-row align-items-center m-2">
  <span class="badge badge-secondary mr-md-1" translate>Missing documents</span>
</div>
<div *ngIf="status == 3" class="d-flex flex-row align-items-center m-2">
  <span class="badge badge-danger mr-md-1" translate>Rejected</span>
</div>
<div *ngIf="status == 4" class="d-flex flex-row align-items-center m-2">
  <span class="badge badge-danger mr-md-1" translate>Waiting for approval</span>
</div>
<div *ngIf="status == 5" class="d-flex flex-row align-items-center m-2">
  <span class="badge badge-danger mr-md-1" translate>Expired</span>
</div>
<div *ngIf="status >= 6 && status <= 8" class="d-flex flex-row align-items-center m-2">
  <span class="badge badage-warning mr-md-1" translate>Expires soon</span>
</div>
