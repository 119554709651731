import { Component, Input, Output, OnChanges, EventEmitter } from '@angular/core';
import { WorkerDocument, User, DocumentFileRef, Worker, Project, WorkerDocumentApproveStatus } from 'src/app/services/sms/api';
import { UserQuery } from '../../user/state/user.query';
import { Observable } from 'rxjs';
import { userHasRole, UserRole } from '../../user/user.util';
import { WorkerService } from '../../authenticated/worker/store/worker.service';
import { NotificationService } from 'src/app/services/notification.service';
import { _ } from 'src/app/util/i18n';
import { TranslateService } from '@ngx-translate/core';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.css']
})
export class DocumentListComponent implements OnChanges {
  faFile = faFileAlt;

  @Input()
  worker: Worker;

  @Input()
  project: Project;

  @Output()
  updateData = new EventEmitter<boolean>();

  positions: { [key: string]: string; } = {};
  user$: Observable<User>;

  selectedDocument: WorkerDocument;
  selectedDocumentIndex = 0;
  selectedDocumentOriginalStatus: WorkerDocumentApproveStatus;

  documentPage = 0;

  slectedDocumentStatusChanged = false;

  aditionalDocument = this.translate.instant(_('Aditional document'));
  unknownDocument = this.translate.instant(_('unknown document nr:'));
  unknownDocumentNoType = this.translate.instant(_('unknown document'));

  constructor(
    private userQuery: UserQuery,
    private workerService: WorkerService,
    private notifications: NotificationService,
    private translate: TranslateService,
  ) { }

  ngOnChanges() {
    this.user$ = this.userQuery.activeUser$;
    this.positions = {};
    this.worker.Positions.reduce((acc, position) => {
      acc.push(...position.RequiredDocumentTypes);
      return acc;
    }, []).map(pos =>
      this.positions[pos.ID] = pos.Name
    );
    if (this.selectedDocument) {
      const newSelectedIndex = this.worker.Documents.reduce((acc, document, i) => document.ID === this.selectedDocument.ID ? i : acc, -1);
      if (newSelectedIndex > -1) {
        this.selectDocument(newSelectedIndex);
      }
      this.updateSelectedDocumentOriginalStatus();
      this.checkDocumentStatusUpdated();
    }
  }

  selectDocument(index: number) {
    this.selectedDocumentIndex = index;
    this.selectedDocument = Object.assign({}, this.worker.Documents[index]);
    if (this.project) {
      if (!this.selectedDocument.ApproveStatuses) {
        this.selectedDocument.ApproveStatuses = [];
      }
      if (this.selectedDocument.ApproveStatuses.every(status => status.ProjectID !== this.project.ID)) {
        this.selectedDocument.ApproveStatuses = [
          ...this.selectedDocument.ApproveStatuses,
          {
            ApproveStatus: this.project.Meta.DefaultDocumentApproveStatus,
            ProjectID: this.project.ID,
            RejectReason: '',
            WorkerDocumentID: this.selectedDocument.ID,
          },
        ];
      }
    }
    this.checkDocumentStatusUpdated();
  }

  updateSelectedDocumentOriginalStatus() {
    if (!this.selectedDocument) {
      return;
    }
    if (!this.project) {
      return;
    }
    this.selectedDocumentOriginalStatus = this.selectedDocument.ApproveStatuses.reduce((acc, item) => item.ProjectID === this.project.ID ? item : acc);
  }

  getStatusFromDocument(ROdocument: WorkerDocument): (WorkerDocumentApproveStatus & { index: number }) {
    const document = Object.assign({}, ROdocument);
    if (!this.project) {
      return;
    }
    const query = document.ApproveStatuses
      ? document.ApproveStatuses.reduce(
        (acc, item, index) => item.ProjectID === this.project.ID ? Object.assign({}, item, { index }) : acc,
        undefined,
      ) as WorkerDocumentApproveStatus & { index: number }
      : undefined;

    if (!query) {
      return {
        ApproveStatus: this.project.Meta.DefaultDocumentApproveStatus,
        ProjectID: this.project.ID,
        RejectReason: '',
        WorkerDocumentID: document.ID,
        index: -1,
      };
    }

    return query;
  }

  get documentStatus(): (WorkerDocumentApproveStatus & { index: number }) {
    return this.getStatusFromDocument(this.selectedDocument);
  }

  get documentStatusI(): number {
    const out = this.documentStatus;
    if (!out) {
      return 0;
    }
    return out.index;
  }

  documentsIsArray = () => Array.isArray(this.worker.Documents);
  noDocuments = () => this.worker.Documents && Array.isArray(this.worker.Documents) && this.worker.Documents.length === 0;

  userHasRole = (user: User, role: UserRole) => userHasRole(user, role);
  isSubcontractor = (user: User) => this.userHasRole(user, UserRole.Admin | UserRole.Subcontractor | UserRole.SubcontractorAdmin);
  isOrg = (user: User) => this.userHasRole(user, UserRole.Admin | UserRole.OrgAdmin | UserRole.OrgUser);
  isSupervisor = (user: User) => this.userHasRole(user, UserRole.SuperVisor);

  async removeDocument(id: number) {
    if (id === undefined || this.worker.ID === undefined) {
      throw new Error('Can\'t remove this document, missing worker ID and/or document ID');
    }
    await this.workerService.removeDocument(this.worker.ID, id);
    this.selectedDocument = undefined;
    this.updateData.emit(true);
  }

  async updateDocumentStatus(document: WorkerDocument) {
    if (this.worker.ID === undefined) {
      throw new Error('Can\'t set document approve status, missing worker ID and/or document ID');
    }
    if (!this.project) {
      return;
    }
    const selectedStatus = this.selectedDocument.ApproveStatuses[this.documentStatusI];
    await this.workerService.setDocumentStatus(this.worker.ID, document.ID, this.project.ID, selectedStatus.ApproveStatus, selectedStatus.RejectReason);
    this.notifications.successTranslated(_('Updated the document status'));

    this.selectedDocument = undefined;
    this.selectDocument(this.selectedDocumentIndex);
    this.selectedDocumentOriginalStatus = undefined;
    this.slectedDocumentStatusChanged = false;

    this.updateData.emit(true);
  }

  checkDocumentStatusUpdated() {
    if (!this.project) {
      return;
    }

    const originalStatus = this.selectedDocumentOriginalStatus;
    const statuses = this.selectedDocument ? this.selectedDocument.ApproveStatuses : undefined;
    if (
      !this.selectedDocument
      || !originalStatus
      || originalStatus.WorkerDocumentID !== statuses[this.documentStatusI].WorkerDocumentID
    ) {
      this.slectedDocumentStatusChanged = false;
      return;
    }
    this.slectedDocumentStatusChanged = originalStatus.ApproveStatus !== statuses[this.documentStatusI].ApproveStatus;
  }

  setDocumentStatus(to: number) {
    const originalStatus = this.selectedDocumentOriginalStatus;
    if (!originalStatus || originalStatus.WorkerDocumentID !== this.selectedDocument.ApproveStatuses[this.documentStatusI].WorkerDocumentID) {
      this.selectedDocumentOriginalStatus = Object.assign({}, this.selectedDocument.ApproveStatuses[this.documentStatusI]);
    }

    if (!this.project) {
      return;
    }

    const ApproveStatuses = Array.from(this.selectedDocument.ApproveStatuses);
    ApproveStatuses[this.documentStatusI] = Object.assign(
      {},
      ApproveStatuses[this.documentStatusI],
      { ApproveStatus: to },
    );

    this.selectedDocument = Object.assign(
      {},
      this.selectedDocument,
      { ApproveStatuses },
    );

    this.checkDocumentStatusUpdated();
  }

  getDocumentPage(files: DocumentFileRef[]) {
    return !files
      ? 0
      : this.documentPage < files.length
        ? this.documentPage
        : this.documentPage < 0
          ? 0
          : files.length - 1;
  }

  getDocument(files: DocumentFileRef[]) {
    return files[this.getDocumentPage(files)];
  }

  previousDocument() {
    this.documentPage = (this.documentPage !== 0)
      ? this.documentPage - 1
      : 0;
  }

  nextDocument(filesLength) {
    this.documentPage = (filesLength - 1 > this.documentPage)
      ? this.documentPage + 1
      : filesLength - 1;
  }

  dvalid(document: WorkerDocument): boolean {
    return document && document.DocumentTypeID > 0 && !!this.positions[document.DocumentTypeID];
  }

  dother(document: WorkerDocument): string {
    return !document
      ? this.unknownDocumentNoType
      : document.DocumentTypeID === -1
        ? this.aditionalDocument
        : this.unknownDocument + ' ' + document.DocumentTypeID;
  }

  parseDateString(date: string | undefined): Date {
    if (!date) {
      return undefined;
    }
    return (Date.parse(date) > 0) ? new Date(date) : null;
  }

  fileReq(document: DocumentFileRef) {
    return () => this.workerService.getDocumentFile(this.worker.ID, document.ID);
  }
}
