<div class="file-list">
  <table class="table" *ngIf="inputFiles && inputFiles.length > 0">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col" translate>File name</th>
        <th *ngIf="!hideActionButton" scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let file of getFiles(); let i = index">
        <th scope="row">{{ i+1 }}</th>
        <td>{{ file.name }}</td>
        <td *ngIf="!hideActionButton">
          <span *ngIf="addButton; else elseBlock" class="clickable" (click)="iconClick(i)" tooltip="{{ 'Click to only upload only some files for this document type' | translate }}">
            <fa-icon [icon]="faPlus"></fa-icon>
          </span>
          <ng-template #elseBlock>
            <span class="clickable" (click)="iconClick(i)">
              <fa-icon [icon]="faMinus"></fa-icon>
            </span>
          </ng-template>
        </td>
      </tr>
    </tbody>
  </table>
</div>
