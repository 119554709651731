import { Component, Input } from '@angular/core';

@Component({
  selector: 'worker-status',
  templateUrl: './worker-status.component.html',
  styleUrls: ['./worker-status.component.css']
})
export class WorkerStatusComponent {
  @Input()
  status: number;
}
