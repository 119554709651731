import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { _ } from '../../../util/i18n';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
})
export class ConfirmModalComponent {
  @Input()
  public title = 'Title';
  @Input()
  public message = '';
  public value: any;
  @Input()
  public type: 'text' | 'password' = 'text';
  @Input()
  public showCancel = true;
  @Input()
  public confirmLabel;

  @Output()
  public result = new EventEmitter<{ result: boolean, value: any }>();

  constructor(public activeModal: NgbActiveModal, private sanitized: DomSanitizer) {
  }

  dismiss() {
    const value = null;
    const result = false;
    this.result.emit({ result, value });
    this.activeModal.close('Dismiss');
  }

  confirm() {
    const value = this.value;
    const result = true;
    this.result.emit({ result, value });
    this.activeModal.close('Confirm');
  }

  getMessage() {
    const message = this.message.replace(/(?:\r\n|\r|\n)/g, '<br>');
    return this.sanitized.bypassSecurityTrustHtml(message);
  }

}
