import { Injectable } from '@angular/core';
import { SnotifyService, SnotifyToastConfig } from 'ng-snotify';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private snotifyService: SnotifyService,
    protected translateService: TranslateService,
  ) {
  }

  translate(input: string) {
    return input ? this.translateService.instant(input) : input;
  }

  notify(body: string, title: string = null, config: SnotifyToastConfig = null) {
    return this.snotifyService.simple(body, title, { showProgressBar: false, ...config });
  }
  notifyTranslated(body: string, title: string = null, config: SnotifyToastConfig = null) {
    return this.notify(this.translate(body), this.translate(title), config);
  }

  success(body: string, title: string = null, config: SnotifyToastConfig = null) {
    return this.snotifyService.success(body, title, { showProgressBar: false, ...config });
  }
  successTranslated(body: string, title: string = null, config: SnotifyToastConfig = null) {
    return this.success(this.translate(body), this.translate(title), config);
  }

  info(body: string, title: string = null, config: SnotifyToastConfig = null) {
    return this.snotifyService.info(body, title, { showProgressBar: false, ...config });
  }
  infoTranslated(body: string, title: string = null, config: SnotifyToastConfig = null) {
    return this.info(this.translate(body), this.translate(title), config);
  }

  warning(body: string, title: string = null, config: SnotifyToastConfig = null) {
    return this.snotifyService.warning(body, title, { showProgressBar: false, ...config });
  }
  warningTranslated(body: string, title: string = null, config: SnotifyToastConfig = null) {
    return this.warning(this.translate(body), this.translate(title), config);
  }

  error(body: string, title: string = null, config: SnotifyToastConfig = null) {
    return this.snotifyService.error(body, title, { showProgressBar: false, ...config });
  }
  errorTranslated(body: string, title: string = null, config: SnotifyToastConfig = null) {
    return this.error(this.translate(body), this.translate(title), config);
  }

  confirm(body: string, title: string = null, config: SnotifyToastConfig = null) {
    return this.snotifyService.confirm(body, title, { showProgressBar: false, ...config });
  }
  confirmTranslated(body: string, title: string = null, config: SnotifyToastConfig = null) {
    return this.confirm(this.translate(body), this.translate(title), config);
  }

  prompt(body: string, title: string = null, config: SnotifyToastConfig = null) {
    return this.snotifyService.prompt(body, title, { showProgressBar: false, ...config });
  }
  promptTranslated(body: string, title: string = null, config: SnotifyToastConfig = null) {
    return this.prompt(this.translate(body), this.translate(title), config);
  }
}
