import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Project, User, OnlyProjectPositions, ProjectPosition } from 'src/app/services/sms/api';
import { WorkerService } from '../../authenticated/worker/store/worker.service';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { userRoleInProject, roleIsOrg, roleIsSubcontractor, UserRole, userHasRole } from '../../user/user.util';
import {
  APIErrorService,
  DocumentType,
  Worker
} from 'src/app/services/sms';
import { _ } from '../../../util/i18n';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../../services/notification.service';
import uniqBy from 'lodash/uniqBy';

interface ProjectPositionWithProject extends ProjectPosition {
  ProjectName: string;
}

@Component({
  selector: 'worker-popup',
  templateUrl: './worker-popup.component.html',
  styleUrls: ['./worker-popup.component.css']
})
export class WorkerPopupComponent implements OnInit, OnDestroy {
  @Input()
  isSupervisor: boolean;

  @Input()
  workerID: number;

  @Input()
  project: Project;

  @Input()
  user: User;

  // The mode is dashboardView when the project is undefined
  // This popup it's behaviour changes a lot when project is not
  // defined so this is an easy way to check it
  mode: 'dashboardView' | 'projectView' | undefined;
  get modeIsDashboard() {
    return this.mode === 'dashboardView';
  }
  get modeIsProject() {
    return this.mode === 'projectView';
  }

  userRole = 0;

  availablePositions: OnlyProjectPositions[] = [];
  availablePositionsList: ProjectPositionWithProject[] = [];

  worker: Worker = undefined;

  // Date picker
  myDpOptions: IAngularMyDpOptions = {
    minYear: 1900,
    maxYear: 2150,
    dateRange: false,
    dateFormat: 'dd-mm-yyyy',
  };
  myDateInit = true;
  selectedDate: IMyDateModel = null;

  // File upload
  workerDocumentUploadDefault = {
    Files: undefined as File[],
    SelectedFiles: undefined as File[],
    DocumentType: undefined as DocumentType,
    Description: '',
    GeneralErr: [] as string[],
  };
  workerDocumentUpload = Object.assign({}, this.workerDocumentUploadDefault);

  requiredDocuments: DocumentType[] = [];
  extraDocuments: DocumentType[] = [];
  allDocuments: DocumentType[] = []; // requiredDocuments and extraDocuments combained

  GeneralErr: string[] = [];
  loading = true;

  updatetimer: any;

  constructor(
    private workerService: WorkerService,
    private errors: APIErrorService,
    private translate: TranslateService,
    private notificationService: NotificationService,
    public activeModal: NgbActiveModal,
  ) {
  }

  async ngOnInit() {
    if (this.isSupervisor === undefined) {
      throw new Error('@Input isSupervisor is undefined');
    }
    if (this.workerID === undefined) {
      throw new Error('@Input workerID is undefined');
    }
    if (this.workerID < 1) {
      throw new Error('@Input workerID is less than 1');
    }
    if (this.user === undefined) {
      throw new Error('@Input user is undefined');
    }

    this.mode = userHasRole(this.user, UserRole.AllOrg) ? 'projectView' : 'dashboardView';

    const now = new Date();
    this.selectedDate = {
      isRange: false,
      singleDate: {
        date: {
          year: now.getFullYear(),
          month: now.getMonth() + 1,
          day: now.getDate(),
        }
      }
    };

    this.workerDocumentUpload = Object.assign({}, this.workerDocumentUploadDefault);

    await this.refreshData();

    this.loading = false;

    this.updatetimer = setInterval(
      () => this.refreshData(),
      1000 * 60 * 3,
    );
  }

  ngOnDestroy() {
    clearInterval(this.updatetimer);
  }

  async refreshData() {
    await this.getWorkerInfo();

    if (this.isSupervisor) {
      return;
    }
    if (this.modeIsProject) {
      this.userRole = userRoleInProject(this.project, this.user);
    } else {
      this.userRole = this.user.UserAccessRules.filter(role => role.SubcontractorID > 0).reduce((acc, role) => (acc | role.UserRole), 0);
      this.availablePositions = await this.workerService.allCanHaveWorkerProjectPositions(this.workerID);
      this.availablePositionsList = this.availablePositions.reduce((acc, project) =>
        [...acc, ...project.Positions.map(position => Object.assign(position, { ProjectName: project.Name }))]
        , []);
    }
  }

  get isSubcontractor() {
    return roleIsSubcontractor(this.userRole);
  }
  get isOrg() {
    return roleIsOrg(this.userRole);
  }

  async getWorkerInfo() {
    const newWorker = await (this.isSupervisor ? this.workerService.workerInfoNoDocuments(this.workerID) : this.workerService.workerInfo(this.workerID));

    if (this.project) {
      newWorker.Positions = newWorker.Positions.filter(position => position.ProjectID === this.project.ID);
    }

    const all = newWorker.Positions.reduce((acc, position) =>
      [
        ...acc,
        ...position.RequiredDocumentTypes.filter(documentType =>
          newWorker.Documents.filter(document =>
            document.DocumentTypeID === documentType.ID
          ).length === 0
        )
      ]
      , []);
    this.requiredDocuments = uniqBy(all, 'ID');

    this.extraDocuments = newWorker.Projects.some(project => project.Meta.AditionalFilesAllowed)
      ? [{ ID: -2, Description: '', Name: this.translate.instant(_('Aditional document(s)')) }]
      : [];
    this.allDocuments = [...this.requiredDocuments, ...this.extraDocuments];

    this.worker = newWorker;
  }

  addDocumentFileUploadEv(files) {
    this.workerDocumentUpload.Files = files;
  }

  async addWorkerDocument() {
    this.workerDocumentUpload.GeneralErr = [];

    const data = this.workerDocumentUpload;
    const files = this.selectedDocumentsToUpload;
    const uploadedFromSelected = this.workerDocumentUpload.SelectedFiles !== undefined && this.workerDocumentUpload.SelectedFiles.length > 0;
    const docType = data.DocumentType;

    if (files === undefined || Object.keys(files).length === 0) {
      this.workerDocumentUpload.GeneralErr = [_('There are no files to upload')];
      return;
    }
    if (!docType) {
      this.workerDocumentUpload.GeneralErr = [_('No document type selected')];
      return;
    }

    const now = new Date();
    const expireDate = this.selectedDate && this.selectedDate.singleDate && this.selectedDate.singleDate.date
      ? this.selectedDate.singleDate.date
      : {
        day: now.getDate(),
        month: now.getMonth() + 1,
        year: now.getFullYear(),
      };
    const day = (`${expireDate.day}`.length === 1 ? '0' : '') + expireDate.day;
    const month = (`${expireDate.month}`.length === 1 ? '0' : '') + expireDate.month;
    const dateString = `${day}-${month}-${expireDate.year}`;

    const errors = await this.errors.tryAndReport(async () => {

      if (docType.ID === -2) {
        await this.workerService.AddAditionalDocuments(
          this.workerID,
          this.workerDocumentUpload.Description,
          files,
        );
      } else {
        await this.workerService.addDocument(
          this.workerID,
          docType.ID,
          dateString,
          this.workerDocumentUpload.Description,
          files,
        );
      }
    }, this.workerDocumentUpload, 'GeneralErr');
    if (Object.keys(errors).length === 0) {
      if (uploadedFromSelected) {
        const resetFilesTo = this.workerDocumentUpload.Files;
        this.workerDocumentUpload = Object.assign({}, this.workerDocumentUploadDefault);
        this.workerDocumentUpload.Files = resetFilesTo;
      } else {
        this.workerDocumentUpload = Object.assign({}, this.workerDocumentUploadDefault);
      }
      this.notificationService.success(
        this.translate.instant(_('Successfully added document'))
      );
    }
    this.getWorkerInfo();
  }

  async updateWorkerPositions() {
    const ids = this.worker.Positions.map(position => position.ID);
    const errors = await this.errors.tryAndReport(() =>
      this.modeIsProject
        ? this.workerService.updateWorkerProjectPosition(
          this.workerID,
          this.project.ID,
          ids
        )
        : this.workerService.updateWorkerPositions(
          this.workerID,
          ids
        )
      , this, 'GeneralErr');
    if (Object.keys(errors).length === 0) {
      this.notificationService.successTranslated(_('Successfully updated worker positions'));
      this.getWorkerInfo();
    }
  }

  newDocumentExpires(document: DocumentType) {
    return document
      ? document.Expires
      : false;
  }

  addDocumentToSelected(index: number) {
    const removed = this.workerDocumentUpload.Files.splice(index, 1);

    if (this.workerDocumentUpload.SelectedFiles === undefined) {
      this.workerDocumentUpload.SelectedFiles = [];
    }
    this.workerDocumentUpload.SelectedFiles.push(removed[0]);
  }

  removeFromSelected(index: number) {
    const removed = this.workerDocumentUpload.SelectedFiles.splice(index, 1);

    if (this.workerDocumentUpload.Files === undefined) {
      this.workerDocumentUpload.Files = [];
    }
    this.workerDocumentUpload.Files.push(removed[0]);
  }

  get selectedDocumentsToUpload(): File[] {
    return (this.workerDocumentUpload.SelectedFiles === undefined || this.workerDocumentUpload.SelectedFiles.length === 0)
      ? this.workerDocumentUpload.Files
      : this.workerDocumentUpload.SelectedFiles;
  }
  get selectedDocumentsToUploadLength(): number {
    const documents = this.selectedDocumentsToUpload;
    return documents ? documents.length : 0;
  }
  get filesLength(): number {
    const files = this.workerDocumentUpload.Files;
    return files ? files.length : 0;
  }
  get selectedFilesLength(): number {
    const files = this.workerDocumentUpload.SelectedFiles;
    return files ? files.length : 0;
  }
  get selectedAndNormalHaveFiles() {
    return this.selectedFilesLength > 0 && this.filesLength > 0;
  }
  get allFilesLength(): number {
    return this.filesLength + this.selectedFilesLength;
  }
}
