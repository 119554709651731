import { UserStore } from './user.store';
import { LoginData, ActivateUser, ResetPasswordConfirmData, UserUpdateReq, LoginRes, User, UpdateSpecialDocument } from '../../../services/sms';
import { SMSService } from '../../../services/sms/service';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '../../../services/notification.service';
import { _ } from '../../../util/i18n';
import { TranslateService } from '@ngx-translate/core';
import { UserQuery } from './user.query';
import { Observable, Subscription } from 'rxjs';
import { AxiosPromise } from 'axios';

export interface RegisterForm {
  Email: string;
  Password: string;
  PasswordConfirm: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnDestroy {
  public user$: Observable<User>;
  private subs: Subscription[] = [];

  constructor(
    private store: UserStore,
    protected smsService: SMSService,
    protected notificationService: NotificationService,
    protected router: Router,
    protected translate: TranslateService,
    private user: UserQuery,
  ) {
    this.user$ = this.user.activeUser$;
    const sub = this.user$.subscribe(user => {
      this.setLanguage((user && user.Preferences && user.Preferences.Language ? user.Preferences.Language : ''));
    });
    this.subs.push(sub);
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  setLanguage(language: string) {
    return ['en-GB', 'nl-NL'].includes(language)
      ? this.translate.use(language)
      : this.translate.use(this.translate.defaultLang);
  }

  public async userMe() {
    const r = await this.smsService.user.userMe();
    const user = r.data.Message;
    this.store.set([user]);
    this.store.setActive(user.ID);
    return user;
  }

  public async userUpdate(form: UserUpdateReq) {
    const out = await this.smsService.user.userUpdate(form);
    if (out && out.data && out.data.Message) {
      this.store.set([out.data.Message]);
    }
  }

  public async userActivate(form: ActivateUser) {
    const out = await this.smsService.user.userActivate(form);
    const { Key, RefreshToken, User } = out.data.Message;
    this.smsService.tokenStorage.setKey(Key.Key);
    this.smsService.tokenStorage.setSecret(Key.Secret);
    this.smsService.tokenStorage.setRefreshToken(RefreshToken);

    this.store.set([User]);
    this.store.setActive(User.ID);
  }

  public async userResetPassword(form: ResetPasswordConfirmData) {
    const out = await this.smsService.user.userResetPasswordConfirm(form);
    const { Key, RefreshToken, User } = out.data.Message;
    this.smsService.tokenStorage.setKey(Key.Key);
    this.smsService.tokenStorage.setSecret(Key.Secret);
    this.smsService.tokenStorage.setRefreshToken(RefreshToken);

    this.store.set([User]);
    this.store.setActive(User.ID);
  }

  public async userLogin(loginForm?: LoginData) {
    const r = await this.smsService.user.userLogin(loginForm);
    return this.setCredentials(r.data.Message);
  }

  public setCredentials(data: LoginRes): User {
    const user = data.User;

    this.smsService.tokenStorage.setKey(data.Key.Key);
    this.smsService.tokenStorage.setSecret(data.Key.Secret);
    this.smsService.tokenStorage.setRefreshToken(data.RefreshToken);
    this.notificationService.success(this.translate.instant(_('Successfully logged in as %emailAddress%'), { emailAddress: user.Email }));

    this.store.set([user]);
    this.store.setActive(user.ID);
    return user;
  }

  public async logout() {
    this.smsService.tokenStorage.setKey(null);
    this.smsService.tokenStorage.setSecret(null);
    this.smsService.tokenStorage.setRefreshToken(null);
    this.notificationService.success(this.translate.instant(_('Successfully logged out')));
    this.store.reset();
    return this.router.navigate(['/login']);
  }

  public async SpecialDocuments(orgID?: number) {
    const r = (orgID)
      ? await this.smsService.organization.orgSpecialDocuments(orgID)
      : await this.smsService.user.userSpecialDocuments();
    return r.data.Message;
  }


  public async UpdateSpecialDocument(orgID: number, specialDocumentID: number, data: UpdateSpecialDocument) {
    const r = await this.smsService.user.updateSpecialDocument(orgID, specialDocumentID, data);
    return r.data.Message;
  }

  public async DeleteSpecialDocument(orgID: number, specialDocumentID: number) {
    await this.smsService.user.deleteSpecialDocument(orgID, specialDocumentID);
  }

  public async CreateSpecialDocument(orgID: number, forRole: number, name: string, description: string, files: File[]) {
    const r = await this.smsService.user.createSpecialDocument(orgID, `${forRole}`, name, description, ...files);
    return r.data.Message;
  }

  public GetSpecialDocumentFile(specialDocumentID, fileID): AxiosPromise<any> {
    return this.smsService.user.specialDocumentFile(specialDocumentID, fileID, { responseType: 'blob' });
  }
}
