<div class="file-uploader">
  <input #fileInput (change)="publish()" type="file" accept="image/*,application/pdf" style="display: none;" multiple="true">
  <div 
    class="input-box" 
    (click)="openFileSelector()" 
    (drop)="onDrop($event)"
    (dragover)="onDragOver($event)"
  >
    <p class="font-weight-bold text-muted">Click to selecte files or dragg them in here</p>
  </div>
</div>
