var DocumentStatusComponent = /** @class */ (function () {
    function DocumentStatusComponent() {
    }
    Object.defineProperty(DocumentStatusComponent.prototype, "expired", {
        get: function () {
            return this.expireDate ? Date.parse(this.expireDate) < Date.parse(new Date()) : false;
        },
        enumerable: true,
        configurable: true
    });
    return DocumentStatusComponent;
}());
export { DocumentStatusComponent };
