import * as tslib_1 from "tslib";
import * as i0 from "@angular/core";
import * as i1 from "./worker.store";
import * as i2 from "../../../../services/sms/service";
var WorkerService = /** @class */ (function () {
    function WorkerService(store, smsService) {
        this.store = store;
        this.smsService = smsService;
    }
    WorkerService.prototype.workers = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var r, workers;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.smsService.worker.workerListAll()];
                    case 1:
                        r = _a.sent();
                        workers = r.data.Message;
                        this.store.set(workers);
                        return [2 /*return*/, workers];
                }
            });
        });
    };
    WorkerService.prototype.projectWorkers = function (projectID) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var r, workers;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.smsService.project.projectWorkers(projectID)];
                    case 1:
                        r = _a.sent();
                        workers = r.data.Message;
                        this.store.set(workers);
                        return [2 /*return*/, workers];
                }
            });
        });
    };
    WorkerService.prototype.createWorker = function (SubcontractorID, body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var r, worker;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.smsService.worker.workerCreate(SubcontractorID, body)];
                    case 1:
                        r = _a.sent();
                        worker = r.data.Message;
                        this.store.add(worker);
                        return [2 /*return*/, worker];
                }
            });
        });
    };
    WorkerService.prototype.updateWorker = function (workerID, body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var r, worker;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.smsService.worker.workerUpdate(workerID, body)];
                    case 1:
                        r = _a.sent();
                        worker = r.data.Message;
                        this.store.update(worker.ID, worker);
                        return [2 /*return*/, worker];
                }
            });
        });
    };
    WorkerService.prototype.removeWorker = function (workerID) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.smsService.worker.workerRemove(workerID)];
                    case 1:
                        _a.sent();
                        this.store.remove(workerID);
                        return [2 /*return*/];
                }
            });
        });
    };
    WorkerService.prototype.workerInfo = function (workerID) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var workerRes, newWorker, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.smsService.worker.workerInfo(workerID)];
                    case 1:
                        workerRes = _b.sent();
                        newWorker = workerRes.data.Message;
                        _a = newWorker;
                        return [4 /*yield*/, this.workerDocuments(workerID)];
                    case 2:
                        _a.Documents = _b.sent();
                        this.store.update(newWorker.ID, newWorker);
                        return [2 /*return*/, newWorker];
                }
            });
        });
    };
    WorkerService.prototype.workerInfoNoDocuments = function (workerID) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var workerRes, newWorker;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.smsService.worker.workerInfo(workerID)];
                    case 1:
                        workerRes = _a.sent();
                        newWorker = workerRes.data.Message;
                        this.store.update(newWorker.ID, newWorker);
                        return [2 /*return*/, newWorker];
                }
            });
        });
    };
    WorkerService.prototype.updateWorkerPositions = function (workerID, IDs) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var r, newWorker;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.smsService.worker.workerSetProjectsPosition(workerID, { IDs: IDs })];
                    case 1:
                        r = _a.sent();
                        newWorker = r.data.Message;
                        this.store.update(newWorker.ID, newWorker);
                        return [2 /*return*/, newWorker];
                }
            });
        });
    };
    WorkerService.prototype.updateWorkerProjectPosition = function (workerID, projectID, IDs) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var r, newWorker;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.smsService.worker.workerSetPosition(workerID, projectID, { IDs: IDs })];
                    case 1:
                        r = _a.sent();
                        newWorker = r.data.Message;
                        this.store.update(newWorker.ID, newWorker);
                        return [2 /*return*/, newWorker];
                }
            });
        });
    };
    WorkerService.prototype.addDocument = function (workerID, documentTypeID, expireDate, description, files) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var r, newWorker;
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, (_a = this.smsService.worker).workerAddDocument.apply(_a, tslib_1.__spread([workerID, documentTypeID, expireDate, description], files))];
                    case 1:
                        r = _b.sent();
                        newWorker = r.data.Message;
                        this.store.update(newWorker.ID, newWorker);
                        return [2 /*return*/, newWorker];
                }
            });
        });
    };
    WorkerService.prototype.AddAditionalDocuments = function (workerID, description, files) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var r, newWorker;
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, (_a = this.smsService.worker).workerAddAditional.apply(_a, tslib_1.__spread([workerID, undefined, description], files))];
                    case 1:
                        r = _b.sent();
                        newWorker = r.data.Message;
                        this.store.update(newWorker.ID, newWorker);
                        return [2 /*return*/, newWorker];
                }
            });
        });
    };
    WorkerService.prototype.removeDocument = function (workerID, documentID) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var r, newWorker;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.smsService.worker.workerDocumentRemove(workerID, documentID)];
                    case 1:
                        r = _a.sent();
                        newWorker = r.data.Message;
                        this.store.update(newWorker.ID, newWorker);
                        return [2 /*return*/, newWorker];
                }
            });
        });
    };
    WorkerService.prototype.workerDocuments = function (workerID) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var r;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.smsService.worker.workerDocuments(workerID)];
                    case 1:
                        r = _a.sent();
                        return [2 /*return*/, r.data.Message];
                }
            });
        });
    };
    WorkerService.prototype.setDocumentStatus = function (workerID, documentID, projectID, NewStatus, RejectReason) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.smsService.worker.workerDocumentSetStatus(workerID, documentID, projectID, { NewStatus: NewStatus, RejectReason: RejectReason })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    WorkerService.prototype.allCanHaveWorkerProjectPositions = function (workerID) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.smsService.worker.workerProjectsPositions(workerID)];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, res.data.Message];
                }
            });
        });
    };
    WorkerService.prototype.getDocumentFile = function (workerID, fileID) {
        return this.smsService.worker.workerDocumentFile(workerID, fileID, { responseType: 'blob' });
    };
    WorkerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WorkerService_Factory() { return new WorkerService(i0.ɵɵinject(i1.WorkerStore), i0.ɵɵinject(i2.SMSService)); }, token: WorkerService, providedIn: "root" });
    return WorkerService;
}());
export { WorkerService };
