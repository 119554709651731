import * as tslib_1 from "tslib";
import { QueryEntity } from '@datorama/akita';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { userHasRole } from '../user.util';
import * as i0 from "@angular/core";
import * as i1 from "./user.store";
var UserQuery = /** @class */ (function (_super) {
    tslib_1.__extends(UserQuery, _super);
    function UserQuery(store) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        _this.isAuthenticated$ = _this.selectCount().pipe(map(function (c) { return Boolean(c > 0); }));
        _this.isNotAuthenticated$ = _this.isAuthenticated$.pipe(map(function (b) { return !b; }));
        _this.activeEmail$ = _this.selectActive(function (user) { return user.Email; });
        _this.activeUser$ = _this.selectActive();
        _this.hasRole$ = function (userRole) { return _this.selectActive(function (user) { return userHasRole(user, userRole); }); };
        return _this;
    }
    UserQuery.prototype.waitForUser = function () {
        var _this = this;
        return new Promise(function (res) {
            var unsubscribe = new Subject();
            var activeUser$ = _this.selectActive();
            activeUser$
                .pipe(takeUntil(unsubscribe))
                .subscribe(function (user) {
                unsubscribe.next();
                unsubscribe.complete();
                res(user);
            });
        });
    };
    UserQuery.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserQuery_Factory() { return new UserQuery(i0.ɵɵinject(i1.UserStore)); }, token: UserQuery, providedIn: "root" });
    return UserQuery;
}(QueryEntity));
export { UserQuery };
