import { User, Project } from '../../services/sms';
import { TranslateService } from '@ngx-translate/core';
import { _ } from '../../util/i18n';
import { Injectable } from '@angular/core';

let t = 1;

export enum UserRole {
  Unused1 = t,
  Unused2 = t <<= 1,
  SuperVisor = t <<= 1,
  Unused4 = t <<= 1,
  Unused5 = t <<= 1,
  Unused6 = t <<= 1,
  Unused7 = t <<= 1,
  Unused8 = t <<= 1,
  Unused9 = t <<= 1,
  Unused10 = t <<= 1,
  Unused11 = t <<= 1,
  Unused12 = t <<= 1,
  Unused13 = t <<= 1,
  Subcontractor = t <<= 1,
  SubcontractorAdmin = t <<= 1,
  Unused16 = t <<= 1,
  Unused17 = t <<= 1,
  OrgUser = t <<= 1,
  OrgAdmin = t <<= 1,
  Unused21 = t <<= 1,
  Admin = t <<= 1,
  AllAdmin = UserRole.SubcontractorAdmin | UserRole.OrgAdmin | UserRole.Admin,
  AllOrg = UserRole.SuperVisor | UserRole.OrgUser | UserRole.OrgAdmin | UserRole.Admin,
  AllSubcontractor = UserRole.Subcontractor | UserRole.SubcontractorAdmin | UserRole.Admin,
  AllProjects = UserRole.Subcontractor | UserRole.SubcontractorAdmin | UserRole.OrgAdmin | UserRole.OrgUser | UserRole.SuperVisor,
  All = (UserRole.Admin << 1) - 1
}

export const getOrgUserRoles = (): number[] => {
  return [UserRole.SuperVisor, UserRole.OrgUser, UserRole.OrgAdmin];
};

export const getSubcontractroUserRoles = (): number[] => {
  return [UserRole.Subcontractor, UserRole.SubcontractorAdmin];
};

export const roleHasAccessTo = (userRule: number, needsToMatch: UserRole): boolean => {
  return (userRule & needsToMatch) !== 0;
};

export const roleIsSupervisor = (userPermissions: number) => roleHasAccessTo(userPermissions, UserRole.SuperVisor);
export const roleIsSomeAdmin = (userPermissions: number) => roleHasAccessTo(userPermissions, UserRole.AllAdmin);
export const roleIsSubcontractor = (userPermissions: number) => roleHasAccessTo(userPermissions, UserRole.AllSubcontractor);
export const roleIsOrg = (userPermissions: number) => roleHasAccessTo(userPermissions, UserRole.AllOrg);
export const roleIsAdmin = (userPermissions: number) => roleHasAccessTo(userPermissions, UserRole.Admin);

// userRoleInProject returns the user it's role in a project
// This value is or'd from all the user it's roles that have access to this project
// So when matching this with a userrole make sure to use:
// `userRole & supervisor !== 0` or `roleHasAccessTo(userRole, supervisor)`
// instaid of
// `userRole === supervisor`
export const userRoleInProject = (project: Project, user: User) => {
  return user.UserAccessRules.filter(
    rule => rule.OrgId > 0
      ? project.OrgID === rule.OrgId
      : project.Subcontractors.filter(subcontractor => subcontractor.ID === rule.SubcontractorID)[0]
  ).reduce((acc, item) => (acc | item.UserRole), 0);
};

export const userHasRole = (user: User, userRole: UserRole) =>
  (user && user.UserAccessRules)
    ? Boolean(user.UserAccessRules.find(userAccess => roleHasAccessTo(userAccess.UserRole, userRole)))
    : false;

export const userIsNotSubcontractro = (user: User) =>
  user.UserAccessRules.some(accessRule => (accessRule.UserRole & UserRole.SuperVisor) === 0);

export const userSubcontractorAccessList = (user: User) => {
  if (user === undefined || user === null) {
    return [];
  }
  return user.UserAccessRules.filter(rule => rule.SubcontractorID > 0);
};

export const userOrgAccessList = (user: User) => {
  if (user === undefined || user === null) {
    return [];
  }
  return user.UserAccessRules.filter(rule => rule.OrgId > 0);
};

export const userUsableAccessList = (user: User) => {
  return [...userSubcontractorAccessList(user), ...userOrgAccessList(user)];
};

@Injectable({
  providedIn: 'root'
})
export class UserRoleName {
  private userRoleWName = {};

  constructor(public translate: TranslateService) {
    this.userRoleWName[UserRole.Unused1] = '';
    this.userRoleWName[UserRole.Unused2] = '';
    this.userRoleWName[UserRole.SuperVisor] = _('Supervisor');
    this.userRoleWName[UserRole.Unused4] = '';
    this.userRoleWName[UserRole.Unused5] = '';
    this.userRoleWName[UserRole.Unused6] = '';
    this.userRoleWName[UserRole.Unused7] = '';
    this.userRoleWName[UserRole.Unused8] = '';
    this.userRoleWName[UserRole.Unused9] = '';
    this.userRoleWName[UserRole.Unused10] = '';
    this.userRoleWName[UserRole.Unused11] = '';
    this.userRoleWName[UserRole.Unused12] = '';
    this.userRoleWName[UserRole.Unused13] = '';
    this.userRoleWName[UserRole.Subcontractor] = _('Subcontractor');
    this.userRoleWName[UserRole.SubcontractorAdmin] = _('Subcontractor admin');
    this.userRoleWName[UserRole.Unused16] = '';
    this.userRoleWName[UserRole.Unused17] = '';
    this.userRoleWName[UserRole.OrgUser] = _('Company user');
    this.userRoleWName[UserRole.OrgAdmin] = _('Company admin');
    this.userRoleWName[UserRole.Unused21] = '';
    this.userRoleWName[UserRole.Admin] = '';
  }

  getHumanReadable = (key: number): string => {
    const humanError = this.userRoleWName[key];
    if (humanError === undefined || humanError === null) {
      throw new Error('User role not found');
    }
    return this.translate.instant(humanError);
  }
}
