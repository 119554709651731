import * as tslib_1 from "tslib";
import { OnChanges, EventEmitter } from '@angular/core';
import { userHasRole, UserRole } from '../../user/user.util';
import { _ } from 'src/app/util/i18n';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
var DocumentListComponent = /** @class */ (function () {
    function DocumentListComponent(userQuery, workerService, notifications, translate) {
        var _this = this;
        this.userQuery = userQuery;
        this.workerService = workerService;
        this.notifications = notifications;
        this.translate = translate;
        this.faFile = faFileAlt;
        this.updateData = new EventEmitter();
        this.positions = {};
        this.selectedDocumentIndex = 0;
        this.documentPage = 0;
        this.slectedDocumentStatusChanged = false;
        this.aditionalDocument = this.translate.instant(_('Aditional document'));
        this.unknownDocument = this.translate.instant(_('unknown document nr:'));
        this.unknownDocumentNoType = this.translate.instant(_('unknown document'));
        this.documentsIsArray = function () { return Array.isArray(_this.worker.Documents); };
        this.noDocuments = function () { return _this.worker.Documents && Array.isArray(_this.worker.Documents) && _this.worker.Documents.length === 0; };
        this.userHasRole = function (user, role) { return userHasRole(user, role); };
        this.isSubcontractor = function (user) { return _this.userHasRole(user, UserRole.Admin | UserRole.Subcontractor | UserRole.SubcontractorAdmin); };
        this.isOrg = function (user) { return _this.userHasRole(user, UserRole.Admin | UserRole.OrgAdmin | UserRole.OrgUser); };
        this.isSupervisor = function (user) { return _this.userHasRole(user, UserRole.SuperVisor); };
    }
    DocumentListComponent.prototype.ngOnChanges = function () {
        var _this = this;
        this.user$ = this.userQuery.activeUser$;
        this.positions = {};
        this.worker.Positions.reduce(function (acc, position) {
            acc.push.apply(acc, tslib_1.__spread(position.RequiredDocumentTypes));
            return acc;
        }, []).map(function (pos) {
            return _this.positions[pos.ID] = pos.Name;
        });
        if (this.selectedDocument) {
            var newSelectedIndex = this.worker.Documents.reduce(function (acc, document, i) { return document.ID === _this.selectedDocument.ID ? i : acc; }, -1);
            if (newSelectedIndex > -1) {
                this.selectDocument(newSelectedIndex);
            }
            this.updateSelectedDocumentOriginalStatus();
            this.checkDocumentStatusUpdated();
        }
    };
    DocumentListComponent.prototype.selectDocument = function (index) {
        var _this = this;
        this.selectedDocumentIndex = index;
        this.selectedDocument = Object.assign({}, this.worker.Documents[index]);
        if (this.project) {
            if (!this.selectedDocument.ApproveStatuses) {
                this.selectedDocument.ApproveStatuses = [];
            }
            if (this.selectedDocument.ApproveStatuses.every(function (status) { return status.ProjectID !== _this.project.ID; })) {
                this.selectedDocument.ApproveStatuses = tslib_1.__spread(this.selectedDocument.ApproveStatuses, [
                    {
                        ApproveStatus: this.project.Meta.DefaultDocumentApproveStatus,
                        ProjectID: this.project.ID,
                        RejectReason: '',
                        WorkerDocumentID: this.selectedDocument.ID,
                    },
                ]);
            }
        }
        this.checkDocumentStatusUpdated();
    };
    DocumentListComponent.prototype.updateSelectedDocumentOriginalStatus = function () {
        var _this = this;
        if (!this.selectedDocument) {
            return;
        }
        if (!this.project) {
            return;
        }
        this.selectedDocumentOriginalStatus = this.selectedDocument.ApproveStatuses.reduce(function (acc, item) { return item.ProjectID === _this.project.ID ? item : acc; });
    };
    DocumentListComponent.prototype.getStatusFromDocument = function (ROdocument) {
        var _this = this;
        var document = Object.assign({}, ROdocument);
        if (!this.project) {
            return;
        }
        var query = document.ApproveStatuses
            ? document.ApproveStatuses.reduce(function (acc, item, index) { return item.ProjectID === _this.project.ID ? Object.assign({}, item, { index: index }) : acc; }, undefined)
            : undefined;
        if (!query) {
            return {
                ApproveStatus: this.project.Meta.DefaultDocumentApproveStatus,
                ProjectID: this.project.ID,
                RejectReason: '',
                WorkerDocumentID: document.ID,
                index: -1,
            };
        }
        return query;
    };
    Object.defineProperty(DocumentListComponent.prototype, "documentStatus", {
        get: function () {
            return this.getStatusFromDocument(this.selectedDocument);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DocumentListComponent.prototype, "documentStatusI", {
        get: function () {
            var out = this.documentStatus;
            if (!out) {
                return 0;
            }
            return out.index;
        },
        enumerable: true,
        configurable: true
    });
    DocumentListComponent.prototype.removeDocument = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (id === undefined || this.worker.ID === undefined) {
                            throw new Error('Can\'t remove this document, missing worker ID and/or document ID');
                        }
                        return [4 /*yield*/, this.workerService.removeDocument(this.worker.ID, id)];
                    case 1:
                        _a.sent();
                        this.selectedDocument = undefined;
                        this.updateData.emit(true);
                        return [2 /*return*/];
                }
            });
        });
    };
    DocumentListComponent.prototype.updateDocumentStatus = function (document) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var selectedStatus;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.worker.ID === undefined) {
                            throw new Error('Can\'t set document approve status, missing worker ID and/or document ID');
                        }
                        if (!this.project) {
                            return [2 /*return*/];
                        }
                        selectedStatus = this.selectedDocument.ApproveStatuses[this.documentStatusI];
                        return [4 /*yield*/, this.workerService.setDocumentStatus(this.worker.ID, document.ID, this.project.ID, selectedStatus.ApproveStatus, selectedStatus.RejectReason)];
                    case 1:
                        _a.sent();
                        this.notifications.successTranslated(_('Updated the document status'));
                        this.selectedDocument = undefined;
                        this.selectDocument(this.selectedDocumentIndex);
                        this.selectedDocumentOriginalStatus = undefined;
                        this.slectedDocumentStatusChanged = false;
                        this.updateData.emit(true);
                        return [2 /*return*/];
                }
            });
        });
    };
    DocumentListComponent.prototype.checkDocumentStatusUpdated = function () {
        if (!this.project) {
            return;
        }
        var originalStatus = this.selectedDocumentOriginalStatus;
        var statuses = this.selectedDocument ? this.selectedDocument.ApproveStatuses : undefined;
        if (!this.selectedDocument
            || !originalStatus
            || originalStatus.WorkerDocumentID !== statuses[this.documentStatusI].WorkerDocumentID) {
            this.slectedDocumentStatusChanged = false;
            return;
        }
        this.slectedDocumentStatusChanged = originalStatus.ApproveStatus !== statuses[this.documentStatusI].ApproveStatus;
    };
    DocumentListComponent.prototype.setDocumentStatus = function (to) {
        var originalStatus = this.selectedDocumentOriginalStatus;
        if (!originalStatus || originalStatus.WorkerDocumentID !== this.selectedDocument.ApproveStatuses[this.documentStatusI].WorkerDocumentID) {
            this.selectedDocumentOriginalStatus = Object.assign({}, this.selectedDocument.ApproveStatuses[this.documentStatusI]);
        }
        if (!this.project) {
            return;
        }
        var ApproveStatuses = Array.from(this.selectedDocument.ApproveStatuses);
        ApproveStatuses[this.documentStatusI] = Object.assign({}, ApproveStatuses[this.documentStatusI], { ApproveStatus: to });
        this.selectedDocument = Object.assign({}, this.selectedDocument, { ApproveStatuses: ApproveStatuses });
        this.checkDocumentStatusUpdated();
    };
    DocumentListComponent.prototype.getDocumentPage = function (files) {
        return !files
            ? 0
            : this.documentPage < files.length
                ? this.documentPage
                : this.documentPage < 0
                    ? 0
                    : files.length - 1;
    };
    DocumentListComponent.prototype.getDocument = function (files) {
        return files[this.getDocumentPage(files)];
    };
    DocumentListComponent.prototype.previousDocument = function () {
        this.documentPage = (this.documentPage !== 0)
            ? this.documentPage - 1
            : 0;
    };
    DocumentListComponent.prototype.nextDocument = function (filesLength) {
        this.documentPage = (filesLength - 1 > this.documentPage)
            ? this.documentPage + 1
            : filesLength - 1;
    };
    DocumentListComponent.prototype.dvalid = function (document) {
        return document && document.DocumentTypeID > 0 && !!this.positions[document.DocumentTypeID];
    };
    DocumentListComponent.prototype.dother = function (document) {
        return !document
            ? this.unknownDocumentNoType
            : document.DocumentTypeID === -1
                ? this.aditionalDocument
                : this.unknownDocument + ' ' + document.DocumentTypeID;
    };
    DocumentListComponent.prototype.parseDateString = function (date) {
        if (!date) {
            return undefined;
        }
        return (Date.parse(date) > 0) ? new Date(date) : null;
    };
    DocumentListComponent.prototype.fileReq = function (document) {
        var _this = this;
        return function () { return _this.workerService.getDocumentFile(_this.worker.ID, document.ID); };
    };
    return DocumentListComponent;
}());
export { DocumentListComponent };
