<div class="app" [ngClass]="
    {
        'is-collapsed': app.layout.isMenuCollapsed,
        'is-opened': app.layout.isMenuOpened,
        'rtl' : app.layout.rtlActived
    }">
  <div class="layout" [ngClass]="headerSelected">

    <div [ngClass]="sidenavSelected">
      <!-- Side Nav START -->
      <div class="side-nav">
        <div class="side-nav-inner">
          <div class="side-nav-logo">
            <a href="/dashboard" [routerLink]="['/dashboard']" class="brand-logo">
              <img class="brand-logo-collapsed" src="/assets/images/logo/logo-sm.png" srcset="/assets/images/logo/logo-sm@2x.png 2x, /assets/images/logo/logo-sm@3x.png 3x">
              <img class="brand-logo-expanded" src="/assets/images/logo/logo.png" srcset="/assets/images/logo/logo@2x.png 2x, /assets/images/logo/logo@3x.png 3x">
            </a>
            <div class="mobile-toggle side-nav-toggle">
              <a href="javascript:void(0);" (click)="app.layout.isMenuCollapsed = !app.layout.isMenuCollapsed">
                <fa-icon [icon]="faArrowCircleLeft"></fa-icon>
              </a>
            </div>
          </div>

          <ul class="side-nav-menu scrollable">
            <li *ngFor="let link of links" class="nav-item dropdown" routerLinkActive="active">
              <a class="dropdown-toggle cursor-pointer" *ngIf="!!link.subItems && link.subItems.length > 0" expand-menu [isExpanded]="link.isExpanded">
                <span class="icon-holder">
                  <fa-icon [icon]="link.icon"></fa-icon>
                </span>
                <span class="title">{{link.title | translate}}</span>
                <span class="arrow">
                  <fa-icon [icon]="link.isExpanded ? faAlignDown : faAngleRight"></fa-icon>
                </span>
              </a>

              <a *ngIf="!link.subItems || link.subItems.length == 0" [routerLink]="[link.to]">
                <span class="icon-holder" *ngIf="link.icon">
                  <fa-icon [icon]="link.icon"></fa-icon>
                </span>
                <span class="title">{{link.title | translate}}</span>
              </a>

              <ul class="dropdown-menu">
                <li *ngFor="let link of link.subItems" class="nav-item" routerLinkActive="active">
                  <a [routerLink]="[link.to]">
                    <span class="icon-holder" *ngIf="link.icon">
                      <fa-icon [icon]="link.icon"></fa-icon>
                    </span>
                    <span class="title">{{link.title | translate}}</span>
                  </a>
                </li>
              </ul>
            </li>
            <li *ngIf="environment.support_email && environment.support_email != ''">
              <a href="mailto:{{environment.support_email}}" target="_blank">
                <span class="icon-holder">
                  <fa-icon [icon]="faQuestion"></fa-icon>
                </span>
                <span class="title" translate>Support</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!-- Side Nav END -->

      <!-- Page Container START -->
      <div class="page-container">

        <!-- Top Nav START -->
        <div class="header navbar">
          <div class="header-container">
            <div class="d-flex align-items-center justify-content-between">
              <div>
                <ul class="nav-left">
                  <li>
                    <a class="side-nav-toggle" href="javascript:void(0);" (click)="app.layout.isMenuCollapsed = !app.layout.isMenuCollapsed">
                      <fa-icon [icon]="faBars" style="font-size: 18px;"></fa-icon>
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h1>{{environment.whitelabel.productName}}</h1>
              </div>
              <div>
                <ul class="nav-right">
                  <li class="user-profile dropdown">
                    <a href="" class="dropdown-toggle" data-toggle="dropdown" *ngIf="user.isAuthenticated$ | async; else login">
                      <div class="user-info">
                        <span class="name pdd-right-5">{{ user.activeEmail$ | async }}</span>
                        <fa-icon [icon]="faCaretDown" class="font-size-10"></fa-icon>
                      </div>
                    </a>
                    <ul class="dropdown-menu">
                      <li>
                        <a href="javascript:void(0)" (click)="userService.logout()">
                          <fa-icon [icon]="faPowerOff" class="pdd-right-10"></fa-icon>
                          <span translate>Logout</span>
                        </a>
                      </li>
                    </ul>

                    <ng-template #login>
                      <a routerLink="/login" translate>Login</a>
                    </ng-template>
                  </li>
                  <li *ngIf="environment.whitelabel && environment.whitelabel.logo">
                <span class="whitelabel-logo-wrapper">
                  <img [src]="environment.whitelabel.logo" class="img-responsive whitelabel-logo">
                </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Top Nav END -->

        <!-- Content Wrapper START -->
        <div class="main-content">
          <div class="row" *ngIf="(user.activeUser$ | async) && !(user.activeUser$ | async).EmailValidated">
            <div class="col-12">
              <div class="alert alert-warning" role="alert">
                <span translate>Warning! You haven't validated your email address yet. Didn't receive an e-mail? Click the following link:</span>&nbsp;<a href="javascript:void(0)" (click)="resendActivationMail()" translate>resend validation email</a>.
              </div>
            </div>
          </div>
          <router-outlet></router-outlet>
        </div>
        <!-- Content Wrapper END -->

        <!-- Footer START -->
        <footer class="content-footer">
          <div class="footer">
            <div class="copyright">
              <span>Klippa © 2019</span>
              <span class="go-right">
                 <!-- <a href="" class="text-gray mrg-right-15">Link 1</a>
                <a href="" class="text-gray">Link 2</a> -->
              </span>
            </div>
          </div>
        </footer>
        <!-- Footer END -->

      </div>
      <!-- Page Container END -->

    </div>
  </div>
</div>
