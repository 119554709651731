import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
var FileUploaderComponent = /** @class */ (function () {
    function FileUploaderComponent() {
        this.files = new EventEmitter();
    }
    FileUploaderComponent.prototype.openFileSelector = function () {
        this.input.nativeElement.click();
    };
    FileUploaderComponent.prototype.publish = function () {
        var e_1, _a;
        var files = [];
        try {
            for (var _b = tslib_1.__values(this.input.nativeElement.files), _c = _b.next(); !_c.done; _c = _b.next()) {
                var file = _c.value;
                files.push(file);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.files.emit(files);
    };
    FileUploaderComponent.prototype.onDrop = function (event) {
        event.preventDefault();
        this.input.nativeElement.files = event.dataTransfer.files;
        this.publish();
    };
    FileUploaderComponent.prototype.onDragOver = function (event) {
        event.stopPropagation();
        event.preventDefault();
    };
    return FileUploaderComponent;
}());
export { FileUploaderComponent };
