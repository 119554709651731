// tslint:disable
/// <reference path="./custom.d.ts" />
import * as tslib_1 from "tslib";
import globalAxios from 'axios';
import { environment } from '../../../environments/environment';
export var BASE_PATH = environment.apiUrl.replace(/\/+$/, "") + "/api/v1";
/**
 *
 * @export
 */
export var COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};
/**
 *
 * @export
 * @class BaseAPI
 */
var BaseAPI = /** @class */ (function () {
    function BaseAPI(configuration, basePath, axios) {
        if (basePath === void 0) { basePath = BASE_PATH; }
        if (axios === void 0) { axios = globalAxios; }
        this.basePath = basePath;
        this.axios = axios;
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
    return BaseAPI;
}());
export { BaseAPI };
;
/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
var RequiredError = /** @class */ (function (_super) {
    tslib_1.__extends(RequiredError, _super);
    function RequiredError(field, msg) {
        var _this = _super.call(this, msg) || this;
        _this.field = field;
        _this.name = "RequiredError";
        return _this;
    }
    return RequiredError;
}(Error));
export { RequiredError };
