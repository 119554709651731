import * as tslib_1 from "tslib";
import { DocumentTypeApi, OrganizationApi, ProjectApi, SubcontractorApi, UserApi, WorkerApi, } from './api';
import { Configuration } from './configuration';
import { AuthManager, LocalTokenStorage } from './auth';
import { environment } from '../../../environments/environment';
import Axios from 'axios';
import * as i0 from "@angular/core";
import * as i1 from "angular-2-local-storage";
var SMSService = /** @class */ (function () {
    function SMSService(localStorageService) {
        var _this = this;
        this.localStorageService = localStorageService;
        this.tokenStorage = new LocalTokenStorage(localStorageService, '');
        var authManager = new AuthManager(this.tokenStorage);
        var BASE_PATH = environment.apiUrl.replace(/\/+$/, '') + '/api/v1';
        var axiosInstance = Axios.create();
        var skipAuthPaths = [
            '/api/v1/user/login',
            '/api/v1/user/activate',
            '/api/v1/user/resetPassword/request',
            '/api/v1/user/resetPassword/confirm',
        ];
        axiosInstance.interceptors.request.use(function (config) {
            var url = config.url;
            // Skip auth headers for certain calls.
            if (skipAuthPaths.includes(url)) {
                return config;
            }
            if (url.includes('/api/v1/subcontractor/validCreateKey/') !== false) {
                return config;
            }
            if (url.includes('/api/v1/subcontractor/createNewFromInviteLink') !== false) {
                return config;
            }
            config.headers = tslib_1.__assign({}, config.headers, authManager.getAuthHeaders());
            return config;
        }, function (error) {
            // Do something with request error
            return Promise.reject(error);
        });
        var vm = this;
        axiosInstance.interceptors.response.use(undefined, function (err) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                // Try to refresh token when this is an error about TokenExpired.
                if (vm.isTokenExpiredResponse(err)) {
                    if (vm.refreshPromise == null) {
                        data = { RefreshToken: authManager.getTokenStorage().getRefreshToken() };
                        vm.refreshPromise = vm.user.userRefreshToken(data).then(function (refreshedKey) {
                            var key = refreshedKey.data.Message.NewKey.Key;
                            var secret = refreshedKey.data.Message.NewKey.Secret;
                            var refreshToken = refreshedKey.data.Message.RefreshToken;
                            if (!key || !secret || !refreshToken) {
                                return Promise.reject('Did not receive new key');
                            }
                            vm.tokenStorage.setKey(key);
                            vm.tokenStorage.setSecret(secret);
                            vm.tokenStorage.setRefreshToken(refreshToken);
                            _this.refreshPromise = null;
                            return Promise.resolve();
                        }).catch(function (refreshErr) {
                            _this.refreshPromise = null;
                            return Promise.reject(refreshErr);
                        });
                    }
                    return [2 /*return*/, vm.refreshPromise.then(function () {
                            err.config.__isRetryRequest = true;
                            return axiosInstance(err.config);
                        }).catch(function (refreshErr) {
                            return Promise.reject(refreshErr);
                        })];
                }
                throw err;
            });
        }); });
        // axiosInstance.interceptors.response.use(response => {
        //   return response;
        // }, error => {
        //   // Do something with response error
        //   return Promise.reject(error);
        // });
        this.configuration = new Configuration({ authManager: authManager });
        this.documentType = new DocumentTypeApi(this.configuration, BASE_PATH, axiosInstance);
        this.organization = new OrganizationApi(this.configuration, BASE_PATH, axiosInstance);
        this.project = new ProjectApi(this.configuration, BASE_PATH, axiosInstance);
        this.subcontractor = new SubcontractorApi(this.configuration, BASE_PATH, axiosInstance);
        this.user = new UserApi(this.configuration, BASE_PATH, axiosInstance);
        this.worker = new WorkerApi(this.configuration, BASE_PATH, axiosInstance);
    }
    SMSService.prototype.isTokenExpiredResponse = function (err) {
        return ((err.config && err.config.__isRetryRequest)
            || !err.response
            || err.response.status !== 401
            || typeof (err.response.data) !== 'object'
            || !err.response.data.ErrorCode
            || err.response.data.ErrorCode !== 'AuthTokenExpired'
            || !this.tokenStorage.getKey()
            || !this.tokenStorage.getSecret()) ? false : true;
    };
    SMSService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SMSService_Factory() { return new SMSService(i0.ɵɵinject(i1.LocalStorageService)); }, token: SMSService, providedIn: "root" });
    return SMSService;
}());
export { SMSService };
