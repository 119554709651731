import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card/card.component';
import { FormGroupComponent } from './forms/form-group/form-group.component';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { LoaderComponent } from './loader/loader.component';
import { ConfirmModalComponent as SecondConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { ActionDropdownComponent } from './action-dropdown/action-dropdown.component';
import { ContentLayoutComponent } from './content-layout/content-layout.component';
import { SortableColumnDirective } from './sortable-column/sortable-column.directive';
import { UserModule } from '../user/user.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormErrorComponent } from './form-error/form-error.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { FileListComponent } from './file-list/file-list.component';
import { WorkerStatusComponent } from './worker-status/worker-status.component';
import { DocumentStatusComponent } from './document-status/document-status.component';
import { DocumentListComponent } from './document-list/document-list.component';
import { LanguagePickerComponent } from './language-picker/language-picker.component';
import { FileViewerComponent } from './file-viewer/file-viewer.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { ImageViewerModule } from '@hallysonh/ngx-imageviewer';
import { WorkerPopupComponent } from './worker-popup/worker-popup.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { BooleanStatusComponent } from './boolean-status/boolean-status.component';
import { SortingIconComponent } from './sorting-icon/sorting-icon.component';

@NgModule({
  declarations: [
    SecondConfirmModalComponent,
    ActionDropdownComponent,
    LanguagePickerComponent,
    SortableColumnDirective,
    DocumentStatusComponent,
    ContentLayoutComponent,
    BooleanStatusComponent,
    DocumentListComponent,
    WorkerStatusComponent,
    ConfirmModalComponent,
    FileUploaderComponent,
    SortingIconComponent,
    WorkerPopupComponent,
    FileViewerComponent,
    FormErrorComponent,
    FormGroupComponent,
    FileListComponent,
    LoaderComponent,
    CardComponent,
  ],
  imports: [
    AngularMyDatePickerModule,
    FontAwesomeModule,
    PdfJsViewerModule,
    ImageViewerModule,
    BsDropdownModule,
    TranslateModule,
    NgSelectModule,
    TooltipModule,
    CommonModule,
    RouterModule,
    FormsModule,
    UserModule,
  ],
  exports: [
    ActionDropdownComponent,
    LanguagePickerComponent,
    SortableColumnDirective,
    DocumentStatusComponent,
    ContentLayoutComponent,
    BooleanStatusComponent,
    ConfirmModalComponent,
    FileUploaderComponent,
    WorkerStatusComponent,
    DocumentListComponent,
    SortingIconComponent,
    WorkerPopupComponent,
    FileViewerComponent,
    FormErrorComponent,
    FormGroupComponent,
    FileListComponent,
    LoaderComponent,
    NgSelectModule,
    CardComponent,
  ],
  entryComponents: [
    SecondConfirmModalComponent,
    ConfirmModalComponent,
    DocumentListComponent,
    WorkerPopupComponent,
  ]
})
export class UiModule {
}
