import {Component, Input, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {_} from '../../../../util/i18n';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
})
export class ConfirmModalComponent implements OnInit {
  @Input()
  public confirmationMessage = _('Are you sure you want to perform this action?');
  @Input()
  public positiveLabel = _('Ok');
  @Input()
  public negativeLabel = _('Cancel');

  constructor(public modalService: BsModalService, public modalRef: BsModalRef, private translateService: TranslateService) {
  }

  ngOnInit() {
    this.confirmationMessage = this.translateService.instant(this.confirmationMessage);
    this.positiveLabel = this.translateService.instant(this.positiveLabel);
    this.negativeLabel = this.translateService.instant(this.negativeLabel);
  }

  positiveAction() {
    this.modalService.setDismissReason('positive');
    this.modalRef.hide();
  }

  negativeAction() {
    this.modalService.setDismissReason('negative');
    this.modalRef.hide();
  }

}
