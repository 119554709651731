import * as tslib_1 from "tslib";
import { _ } from '../../util/i18n';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
var t = 1;
export var UserRole;
(function (UserRole) {
    UserRole[UserRole["Unused1"] = t] = "Unused1";
    UserRole[UserRole["Unused2"] = t <<= 1] = "Unused2";
    UserRole[UserRole["SuperVisor"] = t <<= 1] = "SuperVisor";
    UserRole[UserRole["Unused4"] = t <<= 1] = "Unused4";
    UserRole[UserRole["Unused5"] = t <<= 1] = "Unused5";
    UserRole[UserRole["Unused6"] = t <<= 1] = "Unused6";
    UserRole[UserRole["Unused7"] = t <<= 1] = "Unused7";
    UserRole[UserRole["Unused8"] = t <<= 1] = "Unused8";
    UserRole[UserRole["Unused9"] = t <<= 1] = "Unused9";
    UserRole[UserRole["Unused10"] = t <<= 1] = "Unused10";
    UserRole[UserRole["Unused11"] = t <<= 1] = "Unused11";
    UserRole[UserRole["Unused12"] = t <<= 1] = "Unused12";
    UserRole[UserRole["Unused13"] = t <<= 1] = "Unused13";
    UserRole[UserRole["Subcontractor"] = t <<= 1] = "Subcontractor";
    UserRole[UserRole["SubcontractorAdmin"] = t <<= 1] = "SubcontractorAdmin";
    UserRole[UserRole["Unused16"] = t <<= 1] = "Unused16";
    UserRole[UserRole["Unused17"] = t <<= 1] = "Unused17";
    UserRole[UserRole["OrgUser"] = t <<= 1] = "OrgUser";
    UserRole[UserRole["OrgAdmin"] = t <<= 1] = "OrgAdmin";
    UserRole[UserRole["Unused21"] = t <<= 1] = "Unused21";
    UserRole[UserRole["Admin"] = t <<= 1] = "Admin";
    UserRole[UserRole["AllAdmin"] = UserRole.SubcontractorAdmin | UserRole.OrgAdmin | UserRole.Admin] = "AllAdmin";
    UserRole[UserRole["AllOrg"] = UserRole.SuperVisor | UserRole.OrgUser | UserRole.OrgAdmin | UserRole.Admin] = "AllOrg";
    UserRole[UserRole["AllSubcontractor"] = UserRole.Subcontractor | UserRole.SubcontractorAdmin | UserRole.Admin] = "AllSubcontractor";
    UserRole[UserRole["AllProjects"] = UserRole.Subcontractor | UserRole.SubcontractorAdmin | UserRole.OrgAdmin | UserRole.OrgUser | UserRole.SuperVisor] = "AllProjects";
    UserRole[UserRole["All"] = (UserRole.Admin << 1) - 1] = "All";
})(UserRole || (UserRole = {}));
export var getOrgUserRoles = function () {
    return [UserRole.SuperVisor, UserRole.OrgUser, UserRole.OrgAdmin];
};
export var getSubcontractroUserRoles = function () {
    return [UserRole.Subcontractor, UserRole.SubcontractorAdmin];
};
export var roleHasAccessTo = function (userRule, needsToMatch) {
    return (userRule & needsToMatch) !== 0;
};
export var roleIsSupervisor = function (userPermissions) { return roleHasAccessTo(userPermissions, UserRole.SuperVisor); };
export var roleIsSomeAdmin = function (userPermissions) { return roleHasAccessTo(userPermissions, UserRole.AllAdmin); };
export var roleIsSubcontractor = function (userPermissions) { return roleHasAccessTo(userPermissions, UserRole.AllSubcontractor); };
export var roleIsOrg = function (userPermissions) { return roleHasAccessTo(userPermissions, UserRole.AllOrg); };
export var roleIsAdmin = function (userPermissions) { return roleHasAccessTo(userPermissions, UserRole.Admin); };
// userRoleInProject returns the user it's role in a project
// This value is or'd from all the user it's roles that have access to this project
// So when matching this with a userrole make sure to use:
// `userRole & supervisor !== 0` or `roleHasAccessTo(userRole, supervisor)`
// instaid of
// `userRole === supervisor`
export var userRoleInProject = function (project, user) {
    return user.UserAccessRules.filter(function (rule) { return rule.OrgId > 0
        ? project.OrgID === rule.OrgId
        : project.Subcontractors.filter(function (subcontractor) { return subcontractor.ID === rule.SubcontractorID; })[0]; }).reduce(function (acc, item) { return (acc | item.UserRole); }, 0);
};
export var userHasRole = function (user, userRole) {
    return (user && user.UserAccessRules)
        ? Boolean(user.UserAccessRules.find(function (userAccess) { return roleHasAccessTo(userAccess.UserRole, userRole); }))
        : false;
};
export var userIsNotSubcontractro = function (user) {
    return user.UserAccessRules.some(function (accessRule) { return (accessRule.UserRole & UserRole.SuperVisor) === 0; });
};
export var userSubcontractorAccessList = function (user) {
    if (user === undefined || user === null) {
        return [];
    }
    return user.UserAccessRules.filter(function (rule) { return rule.SubcontractorID > 0; });
};
export var userOrgAccessList = function (user) {
    if (user === undefined || user === null) {
        return [];
    }
    return user.UserAccessRules.filter(function (rule) { return rule.OrgId > 0; });
};
export var userUsableAccessList = function (user) {
    return tslib_1.__spread(userSubcontractorAccessList(user), userOrgAccessList(user));
};
var UserRoleName = /** @class */ (function () {
    function UserRoleName(translate) {
        var _this = this;
        this.translate = translate;
        this.userRoleWName = {};
        this.getHumanReadable = function (key) {
            var humanError = _this.userRoleWName[key];
            if (humanError === undefined || humanError === null) {
                throw new Error('User role not found');
            }
            return _this.translate.instant(humanError);
        };
        this.userRoleWName[UserRole.Unused1] = '';
        this.userRoleWName[UserRole.Unused2] = '';
        this.userRoleWName[UserRole.SuperVisor] = _('Supervisor');
        this.userRoleWName[UserRole.Unused4] = '';
        this.userRoleWName[UserRole.Unused5] = '';
        this.userRoleWName[UserRole.Unused6] = '';
        this.userRoleWName[UserRole.Unused7] = '';
        this.userRoleWName[UserRole.Unused8] = '';
        this.userRoleWName[UserRole.Unused9] = '';
        this.userRoleWName[UserRole.Unused10] = '';
        this.userRoleWName[UserRole.Unused11] = '';
        this.userRoleWName[UserRole.Unused12] = '';
        this.userRoleWName[UserRole.Unused13] = '';
        this.userRoleWName[UserRole.Subcontractor] = _('Subcontractor');
        this.userRoleWName[UserRole.SubcontractorAdmin] = _('Subcontractor admin');
        this.userRoleWName[UserRole.Unused16] = '';
        this.userRoleWName[UserRole.Unused17] = '';
        this.userRoleWName[UserRole.OrgUser] = _('Company user');
        this.userRoleWName[UserRole.OrgAdmin] = _('Company admin');
        this.userRoleWName[UserRole.Unused21] = '';
        this.userRoleWName[UserRole.Admin] = '';
    }
    UserRoleName.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserRoleName_Factory() { return new UserRoleName(i0.ɵɵinject(i1.TranslateService)); }, token: UserRoleName, providedIn: "root" });
    return UserRoleName;
}());
export { UserRoleName };
