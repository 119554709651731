import * as tslib_1 from "tslib";
import { ElementRef, OnChanges } from '@angular/core';
import { ImageViewerConfig, createButtonConfig } from '@hallysonh/ngx-imageviewer';
var MY_IMAGEVIEWER_CONFIG = {
    width: 600,
    height: 800,
    loadingMessage: ' ',
    buttonStyle: {
        iconFontFamily: 'fontello',
        alpha: 0.5,
        hoverAlpha: 0.7,
        bgStyle: '#000000',
        iconStyle: '#ffffff',
        borderStyle: '#000000',
        borderWidth: 0 // buttons' border width (0 == disabled)
    },
    bgStyle: 'transparent',
    zoomOutButton: createButtonConfig(String.fromCharCode(0xf010), 'Zoom out', 0),
    zoomInButton: createButtonConfig(String.fromCharCode(0xf00e), 'Zoom in', 1),
    rotateLeftButton: createButtonConfig(String.fromCharCode(0xf0e2), 'Rotate left', 2),
    rotateRightButton: createButtonConfig(String.fromCharCode(0xf01e), 'Rotate right', 3),
    resetButton: createButtonConfig(String.fromCharCode(0xf0b2), 'Reset zoom', 4)
};
var ɵ0 = MY_IMAGEVIEWER_CONFIG;
var FileViewerComponent = /** @class */ (function () {
    function FileViewerComponent() {
        this.currentID = '';
        this.isPDF = false;
        this.loading = true;
        this.unknownType = false;
        this.blobUrl = '';
    }
    FileViewerComponent.prototype.downloadFile = function () {
        var link = document.createElement('a');
        link.download = this.filename;
        link.href = this.blobUrl;
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    FileViewerComponent.prototype.ngOnChanges = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var r, contentType;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.id === this.currentID) {
                            return [2 /*return*/];
                        }
                        this.currentID = this.id;
                        this.loading = true;
                        return [4 /*yield*/, this.httpReq()];
                    case 1:
                        r = _a.sent();
                        contentType = r.headers['content-type'];
                        this.blob = r.data;
                        this.blobUrl = URL.createObjectURL(this.blob);
                        this.unknownType = false;
                        this.isPDF = /application\/((x|apple)-)?pdf/.test(contentType);
                        if (!this.isPDF) {
                            this.unknownType = !/^image/.test(contentType);
                        }
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    return FileViewerComponent;
}());
export { FileViewerComponent };
export { ɵ0 };
