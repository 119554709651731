import { Component, Input } from '@angular/core';

@Component({
  selector: 'document-status',
  templateUrl: './document-status.component.html',
  styleUrls: ['./document-status.component.css']
})
export class DocumentStatusComponent {
  @Input()
  status: number;

  @Input()
  expireDate: Date | undefined;

  get expired(): boolean {
    return this.expireDate ? Date.parse(this.expireDate as any) < Date.parse(new Date() as any) : false;
  }
}
