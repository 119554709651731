import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {UserQuery} from '../user/state/user.query';
import {first} from 'rxjs/operators';
import {UserService} from '../user/state/user.service';
import {SMSService} from '../../services/sms/service';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserResolver implements Resolve<any> {

  constructor(protected userQuery: UserQuery, protected userService: UserService, protected smsService: SMSService) {
  }

  resolve(route: ActivatedRouteSnapshot) {
    return new Promise((resolve => {
      this.userQuery.isAuthenticated$.pipe(first()).subscribe(isAuthenticated => {
        if (!isAuthenticated && this.smsService.tokenStorage.getKey()) {
          this.userService.userMe().then(user => {
            resolve(user);
          }).catch(e => {
            resolve(null);
          });
        } else {
          /* Authenticated */
          resolve(null);
        }
      });
    }));
  }
}
