import * as tslib_1 from "tslib";
import { _ } from '../../util/i18n';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../notification.service";
var bindErrs = function (errorKey, bindto, defaultErrList, errorsToBind) {
    var currentSelection = bindto;
    var status = errorKey.split('.').every(function (path, i, arr) {
        if (currentSelection === undefined || currentSelection === null) {
            return false;
        }
        if (i === arr.length - 1) {
            currentSelection = currentSelection[path + 'Err'];
        }
        else {
            currentSelection = currentSelection[path];
        }
        return currentSelection !== undefined && currentSelection !== null;
    });
    if (status && currentSelection !== undefined && currentSelection !== null) {
        currentSelection.push.apply(currentSelection, tslib_1.__spread(errorsToBind));
    }
    else {
        defaultErrList.push.apply(defaultErrList, tslib_1.__spread(errorsToBind));
    }
};
var ɵ0 = bindErrs;
var APIErrorService = /** @class */ (function () {
    function APIErrorService(translate, notificationService) {
        this.translate = translate;
        this.notificationService = notificationService;
        this.errors = {
            INVALID_EMAIL: _('Invalid email address'),
            INVALID_PASSWORD: _('Password does not match requirements'),
            INVALID_LANG: _('Invalid language'),
            INVALID_TYPE: _('Invalid type'),
            INVALID_DATE: _('Invalid date'),
            DATE_TOO_EARLY: _('Date too early'),
            DATE_TOO_LATE: _('Date too late'),
            TOO_SHORT: _('Value too short'),
            TOO_LONG: _('Value too long'),
            VALUES_NO_MATCH: _('Passwords do not match'),
            'User already registered': _('Email already used'),
            INVALID_KEY: _('Invalid Key'),
            INPUT_EMPTY: _('Value can\'t be empty'),
            'Email already used': _('Email address is already used'),
            ORG_ALREADY_EXSIST: _('Company already exists'),
            'Name already used': _('Name already used'),
            'email or password incorect': _('Email or password incorect'),
            VALUE_REQUIRED: _('Value is required'),
            'Project already exsists': _('The project already exists'),
            UserAlreadyValidated: _('The user is already validated'),
            UserEmailValidateKeyMismatch: _('The given validation key does not match the key of the user'),
            WRONG_PASSWORD: _('The password that you entered is incorrect'),
            DUPLICATED_ENTRIES: _('Data submitted already exists'),
            DOCUMENT_TYPE_ALREADY_EXISTS: _('There is already a document with this name'),
            WORKER_ALREADY_HAS_DOCUMENT_TYPE: _('Worker already has this document'),
            'worker already exsists': _('Worker already exists'),
            'account not found': _('Account not found'),
            INTERNAL_BROWSER_ERROR: _('Unknown browser error'),
            'account disabled': _('Your account is disabled'),
            CANT_DEL_DOC_TYPE_WITH_DOCS: _('Document type still in use, can\'t remove it'),
            FILE_TO_LARGE: _('A file you are trying to upload is to big'),
            DESCRIPTION_REQUIRED: _('Description is required'),
            INVALID_APPROVE_STATUS: _('Invalid document approve status'),
            // "": _(''),
            // "": _(''),
            // "": _(''),
            // "": _(''),
            // "": _(''),
            // "": _(''),
            // "": _(''),
            // "": _(''),
            // "": _(''),
            // "": _(''),
            // "": _(''),
            undefined: _('Unknown error'),
        };
    }
    APIErrorService.prototype.getErr = function (key) {
        var humanError = this.errors[key];
        if (humanError === undefined || humanError === null) {
            humanError = this.errors.undefined;
        }
        return this.translate.instant(humanError);
    };
    APIErrorService.prototype.notifyAPIError = function (data) {
        if (typeof data.response !== 'object' && typeof data.response.data !== 'object') {
            this.notificationService.error(this.translate.instant(_('Something went wrong, please try again.')));
            return;
        }
        if (data.response.data.ErrorCode && this.errors[data.response.data.ErrorCode]) {
            this.notificationService.error(this.translate.instant(this.errors[data.response.data.ErrorCode]));
            return;
        }
        if (data.response.data.ErrorMessage && data.response.data.ErrorMessage !== '') {
            this.notificationService.error(data.response.data.ErrorMessage);
            return;
        }
        this.notificationService.error(this.translate.instant(_('Something went wrong, please try again.')));
        return;
    };
    APIErrorService.prototype.tryAndReport = function (func, thisBinding, bindToThisDefualtVal) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var output, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        output = {};
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, func()];
                    case 2:
                        _a.sent();
                        output = this.handleAPIError({}, thisBinding, bindToThisDefualtVal);
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        output = this.handleAPIError(error_1, thisBinding, bindToThisDefualtVal);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, output];
                }
            });
        });
    };
    APIErrorService.prototype.handleAPIError = function (err, bindToThis, bindToThisDefualtVal) {
        var _this = this;
        var output = {};
        switch (typeof err) {
            case 'string':
                var undefinedOutput = 'undefined';
                output[undefinedOutput] = [this.getErr(err)];
                break;
            case 'object':
                var loopOver_1 = err;
                if (err.response && err.response.data && typeof err.response.data.ErrorMessage !== undefined) {
                    loopOver_1 = err.response.data.ErrorMessage;
                }
                if (typeof loopOver_1 === 'string') {
                    return this.handleAPIError(loopOver_1, bindToThis, bindToThisDefualtVal);
                }
                if ('message' in loopOver_1 && 'stack' in loopOver_1 && typeof loopOver_1.message === 'string') {
                    console.log('javascript error:', err);
                    var undefinedOutput_1 = 'undefined';
                    output[undefinedOutput_1] = [this.getErr('INTERNAL_BROWSER_ERROR')];
                }
                else {
                    output = Object.keys(loopOver_1).reduce(function (acc, key) {
                        var value = loopOver_1[key];
                        acc[key] = Array.isArray(value)
                            ? value.map(function (item) { return _this.getErr(item); })
                            : [_this.getErr(value)];
                        return acc;
                    }, {});
                }
                break;
            default:
                console.log('Unknown error type:');
                console.dir(err);
        }
        // Filter out the duplicates
        Object.keys(output).map(function (errorKey) {
            output[errorKey] = output[errorKey].filter(function (item, pos) {
                return output[errorKey].indexOf(item) === pos;
            });
        });
        // Bind the errors directly to the component
        if (bindToThis && bindToThisDefualtVal) {
            bindToThis[bindToThisDefualtVal] = [];
            // Empty all form errors
            Object.keys(bindToThis).map(function (key) {
                if (bindToThis[key] instanceof Object && !bindToThis[key]) {
                    Object.keys(bindToThis[key]).map(function (subKey) {
                        if (/.+Err$/.test(subKey)) {
                            bindToThis[key][subKey] = [];
                        }
                    });
                }
                if (/.+Err$/.test(key) && bindToThis[key] instanceof Array) {
                    bindToThis[key] = [];
                }
            });
            // Add the errors
            Object.keys(output).map(function (key) {
                bindErrs(key, bindToThis, bindToThis[bindToThisDefualtVal], output[key]);
            });
        }
        return output;
    };
    APIErrorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function APIErrorService_Factory() { return new APIErrorService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.NotificationService)); }, token: APIErrorService, providedIn: "root" });
    return APIErrorService;
}());
export { APIErrorService };
export { ɵ0 };
