import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { userRoleInProject, roleIsOrg, roleIsSubcontractor, UserRole, userHasRole } from '../../user/user.util';
import { _ } from '../../../util/i18n';
import uniqBy from 'lodash/uniqBy';
var WorkerPopupComponent = /** @class */ (function () {
    function WorkerPopupComponent(workerService, errors, translate, notificationService, activeModal) {
        this.workerService = workerService;
        this.errors = errors;
        this.translate = translate;
        this.notificationService = notificationService;
        this.activeModal = activeModal;
        this.userRole = 0;
        this.availablePositions = [];
        this.availablePositionsList = [];
        this.worker = undefined;
        // Date picker
        this.myDpOptions = {
            minYear: 1900,
            maxYear: 2150,
            dateRange: false,
            dateFormat: 'dd-mm-yyyy',
        };
        this.myDateInit = true;
        this.selectedDate = null;
        // File upload
        this.workerDocumentUploadDefault = {
            Files: undefined,
            SelectedFiles: undefined,
            DocumentType: undefined,
            Description: '',
            GeneralErr: [],
        };
        this.workerDocumentUpload = Object.assign({}, this.workerDocumentUploadDefault);
        this.requiredDocuments = [];
        this.extraDocuments = [];
        this.allDocuments = []; // requiredDocuments and extraDocuments combained
        this.GeneralErr = [];
        this.loading = true;
    }
    Object.defineProperty(WorkerPopupComponent.prototype, "modeIsDashboard", {
        get: function () {
            return this.mode === 'dashboardView';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkerPopupComponent.prototype, "modeIsProject", {
        get: function () {
            return this.mode === 'projectView';
        },
        enumerable: true,
        configurable: true
    });
    WorkerPopupComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var now;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.isSupervisor === undefined) {
                            throw new Error('@Input isSupervisor is undefined');
                        }
                        if (this.workerID === undefined) {
                            throw new Error('@Input workerID is undefined');
                        }
                        if (this.workerID < 1) {
                            throw new Error('@Input workerID is less than 1');
                        }
                        if (this.user === undefined) {
                            throw new Error('@Input user is undefined');
                        }
                        this.mode = userHasRole(this.user, UserRole.AllOrg) ? 'projectView' : 'dashboardView';
                        now = new Date();
                        this.selectedDate = {
                            isRange: false,
                            singleDate: {
                                date: {
                                    year: now.getFullYear(),
                                    month: now.getMonth() + 1,
                                    day: now.getDate(),
                                }
                            }
                        };
                        this.workerDocumentUpload = Object.assign({}, this.workerDocumentUploadDefault);
                        return [4 /*yield*/, this.refreshData()];
                    case 1:
                        _a.sent();
                        this.loading = false;
                        this.updatetimer = setInterval(function () { return _this.refreshData(); }, 1000 * 60 * 3);
                        return [2 /*return*/];
                }
            });
        });
    };
    WorkerPopupComponent.prototype.ngOnDestroy = function () {
        clearInterval(this.updatetimer);
    };
    WorkerPopupComponent.prototype.refreshData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.getWorkerInfo()];
                    case 1:
                        _b.sent();
                        if (this.isSupervisor) {
                            return [2 /*return*/];
                        }
                        if (!this.modeIsProject) return [3 /*break*/, 2];
                        this.userRole = userRoleInProject(this.project, this.user);
                        return [3 /*break*/, 4];
                    case 2:
                        this.userRole = this.user.UserAccessRules.filter(function (role) { return role.SubcontractorID > 0; }).reduce(function (acc, role) { return (acc | role.UserRole); }, 0);
                        _a = this;
                        return [4 /*yield*/, this.workerService.allCanHaveWorkerProjectPositions(this.workerID)];
                    case 3:
                        _a.availablePositions = _b.sent();
                        this.availablePositionsList = this.availablePositions.reduce(function (acc, project) {
                            return tslib_1.__spread(acc, project.Positions.map(function (position) { return Object.assign(position, { ProjectName: project.Name }); }));
                        }, []);
                        _b.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(WorkerPopupComponent.prototype, "isSubcontractor", {
        get: function () {
            return roleIsSubcontractor(this.userRole);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkerPopupComponent.prototype, "isOrg", {
        get: function () {
            return roleIsOrg(this.userRole);
        },
        enumerable: true,
        configurable: true
    });
    WorkerPopupComponent.prototype.getWorkerInfo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var newWorker, all;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, (this.isSupervisor ? this.workerService.workerInfoNoDocuments(this.workerID) : this.workerService.workerInfo(this.workerID))];
                    case 1:
                        newWorker = _a.sent();
                        if (this.project) {
                            newWorker.Positions = newWorker.Positions.filter(function (position) { return position.ProjectID === _this.project.ID; });
                        }
                        all = newWorker.Positions.reduce(function (acc, position) {
                            return tslib_1.__spread(acc, position.RequiredDocumentTypes.filter(function (documentType) {
                                return newWorker.Documents.filter(function (document) {
                                    return document.DocumentTypeID === documentType.ID;
                                }).length === 0;
                            }));
                        }, []);
                        this.requiredDocuments = uniqBy(all, 'ID');
                        this.extraDocuments = newWorker.Projects.some(function (project) { return project.Meta.AditionalFilesAllowed; })
                            ? [{ ID: -2, Description: '', Name: this.translate.instant(_('Aditional document(s)')) }]
                            : [];
                        this.allDocuments = tslib_1.__spread(this.requiredDocuments, this.extraDocuments);
                        this.worker = newWorker;
                        return [2 /*return*/];
                }
            });
        });
    };
    WorkerPopupComponent.prototype.addDocumentFileUploadEv = function (files) {
        this.workerDocumentUpload.Files = files;
    };
    WorkerPopupComponent.prototype.addWorkerDocument = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, files, uploadedFromSelected, docType, now, expireDate, day, month, dateString, errors, resetFilesTo;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.workerDocumentUpload.GeneralErr = [];
                        data = this.workerDocumentUpload;
                        files = this.selectedDocumentsToUpload;
                        uploadedFromSelected = this.workerDocumentUpload.SelectedFiles !== undefined && this.workerDocumentUpload.SelectedFiles.length > 0;
                        docType = data.DocumentType;
                        if (files === undefined || Object.keys(files).length === 0) {
                            this.workerDocumentUpload.GeneralErr = [_('There are no files to upload')];
                            return [2 /*return*/];
                        }
                        if (!docType) {
                            this.workerDocumentUpload.GeneralErr = [_('No document type selected')];
                            return [2 /*return*/];
                        }
                        now = new Date();
                        expireDate = this.selectedDate && this.selectedDate.singleDate && this.selectedDate.singleDate.date
                            ? this.selectedDate.singleDate.date
                            : {
                                day: now.getDate(),
                                month: now.getMonth() + 1,
                                year: now.getFullYear(),
                            };
                        day = (("" + expireDate.day).length === 1 ? '0' : '') + expireDate.day;
                        month = (("" + expireDate.month).length === 1 ? '0' : '') + expireDate.month;
                        dateString = day + "-" + month + "-" + expireDate.year;
                        return [4 /*yield*/, this.errors.tryAndReport(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!(docType.ID === -2)) return [3 /*break*/, 2];
                                            return [4 /*yield*/, this.workerService.AddAditionalDocuments(this.workerID, this.workerDocumentUpload.Description, files)];
                                        case 1:
                                            _a.sent();
                                            return [3 /*break*/, 4];
                                        case 2: return [4 /*yield*/, this.workerService.addDocument(this.workerID, docType.ID, dateString, this.workerDocumentUpload.Description, files)];
                                        case 3:
                                            _a.sent();
                                            _a.label = 4;
                                        case 4: return [2 /*return*/];
                                    }
                                });
                            }); }, this.workerDocumentUpload, 'GeneralErr')];
                    case 1:
                        errors = _a.sent();
                        if (Object.keys(errors).length === 0) {
                            if (uploadedFromSelected) {
                                resetFilesTo = this.workerDocumentUpload.Files;
                                this.workerDocumentUpload = Object.assign({}, this.workerDocumentUploadDefault);
                                this.workerDocumentUpload.Files = resetFilesTo;
                            }
                            else {
                                this.workerDocumentUpload = Object.assign({}, this.workerDocumentUploadDefault);
                            }
                            this.notificationService.success(this.translate.instant(_('Successfully added document')));
                        }
                        this.getWorkerInfo();
                        return [2 /*return*/];
                }
            });
        });
    };
    WorkerPopupComponent.prototype.updateWorkerPositions = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ids, errors;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        ids = this.worker.Positions.map(function (position) { return position.ID; });
                        return [4 /*yield*/, this.errors.tryAndReport(function () {
                                return _this.modeIsProject
                                    ? _this.workerService.updateWorkerProjectPosition(_this.workerID, _this.project.ID, ids)
                                    : _this.workerService.updateWorkerPositions(_this.workerID, ids);
                            }, this, 'GeneralErr')];
                    case 1:
                        errors = _a.sent();
                        if (Object.keys(errors).length === 0) {
                            this.notificationService.successTranslated(_('Successfully updated worker positions'));
                            this.getWorkerInfo();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    WorkerPopupComponent.prototype.newDocumentExpires = function (document) {
        return document
            ? document.Expires
            : false;
    };
    WorkerPopupComponent.prototype.addDocumentToSelected = function (index) {
        var removed = this.workerDocumentUpload.Files.splice(index, 1);
        if (this.workerDocumentUpload.SelectedFiles === undefined) {
            this.workerDocumentUpload.SelectedFiles = [];
        }
        this.workerDocumentUpload.SelectedFiles.push(removed[0]);
    };
    WorkerPopupComponent.prototype.removeFromSelected = function (index) {
        var removed = this.workerDocumentUpload.SelectedFiles.splice(index, 1);
        if (this.workerDocumentUpload.Files === undefined) {
            this.workerDocumentUpload.Files = [];
        }
        this.workerDocumentUpload.Files.push(removed[0]);
    };
    Object.defineProperty(WorkerPopupComponent.prototype, "selectedDocumentsToUpload", {
        get: function () {
            return (this.workerDocumentUpload.SelectedFiles === undefined || this.workerDocumentUpload.SelectedFiles.length === 0)
                ? this.workerDocumentUpload.Files
                : this.workerDocumentUpload.SelectedFiles;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkerPopupComponent.prototype, "selectedDocumentsToUploadLength", {
        get: function () {
            var documents = this.selectedDocumentsToUpload;
            return documents ? documents.length : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkerPopupComponent.prototype, "filesLength", {
        get: function () {
            var files = this.workerDocumentUpload.Files;
            return files ? files.length : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkerPopupComponent.prototype, "selectedFilesLength", {
        get: function () {
            var files = this.workerDocumentUpload.SelectedFiles;
            return files ? files.length : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkerPopupComponent.prototype, "selectedAndNormalHaveFiles", {
        get: function () {
            return this.selectedFilesLength > 0 && this.filesLength > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkerPopupComponent.prototype, "allFilesLength", {
        get: function () {
            return this.filesLength + this.selectedFilesLength;
        },
        enumerable: true,
        configurable: true
    });
    return WorkerPopupComponent;
}());
export { WorkerPopupComponent };
