<p *ngIf="noDocuments()" translate>This worker doesn't have any documents yet</p>
<div class="document-list" *ngIf="!noDocuments()">
  <div *ngIf="documentsIsArray()" class="accordion" id="documentsAccordion">
    <table class="table table-sm table-hover">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col" translate>Name</th>
          <th scope="col" *ngIf="!!project" translate>Status</th>
          <th scope="col" translate>Expire date</th>
          <th scope="col" *ngIf="!isSupervisor(user$ | async)"></th>
        </tr>
      </thead>
      <tbody>
        <ng-template ngFor let-document [ngForOf]="worker.Documents" let-i="index">
          <tr [class]="selectedDocument && i === selectedDocumentIndex ? 'table-active' : ''">
            <th>{{i+1}}</th>
            <td>
              {{ dvalid(document)
                ? positions[document.DocumentTypeID]
                : dother(document)
              }}
            </td>
            <td *ngIf="!!project">
              <document-status [status]="getStatusFromDocument(document).ApproveStatus" [expireDate]="parseDateString(document.ExpirationDate)"></document-status>
            </td>
            <td>
              <span *ngIf="parseDateString(document.ExpirationDate)">{{ (parseDateString(document.ExpirationDate) | date:'dd/MM/y' )}}</span>
              <span class="text-muted" *ngIf="!parseDateString(document.ExpirationDate)" translate>Not set</span>
            </td>
            <td *ngIf="!isSupervisor(user$ | async)">
              <span (click)="selectDocument(i)" tooltip="{{'View the document(s)'|translate}}" style="color: black; cursor: pointer;">
                <fa-icon [icon]="faFile"></fa-icon>
              </span>
            </td>
          </tr>
        </ng-template>
      </tbody>
    </table>
    <div *ngIf="selectedDocument">
      <div class="d-flex justify-content-between align-items-center">
        <button
          tooltip="{{
            ('Go to the previous document:'|translate)
            + ' '
            + (dvalid(worker.Documents[selectedDocumentIndex-1])
              ? positions[worker.Documents[selectedDocumentIndex-1].DocumentTypeID]
              : dother(worker.Documents[selectedDocumentIndex-1])
            )
          }}"
          [disabled]="selectedDocumentIndex == 0"
          class="btn btn-sm btn-primary"
          type="button"
          (click)="selectDocument(selectedDocumentIndex - 1)"
          translate
        >Previous</button>
        <button
          *ngIf="isSubcontractor(user$|async)"
          tooltip="{{'Remove this document from the worker'|translate}}"
          class="btn btn-sm btn-danger"
          type="button"
          (click)="removeDocument(selectedDocument.ID)"
          translate
        >Remove</button>
        <button
          tooltip="{{
            ('Go to the next document:'|translate)
            + ' '
            + (dvalid(worker.Documents[selectedDocumentIndex+1])
              ? positions[worker.Documents[selectedDocumentIndex+1].DocumentTypeID]
              : dother(worker.Documents[selectedDocumentIndex+1])
            )
          }}"
          [disabled]="(worker.Documents.length - 1) == selectedDocumentIndex"
          class="btn btn-sm btn-primary"
          type="button"
          (click)="selectDocument(selectedDocumentIndex + 1)"
        >Next</button>
      </div>
      <div *ngIf="isOrg(user$|async) && !!project && documentStatusI >= 0 && selectedDocument.ApproveStatuses[documentStatusI]" class="mt-md-2 mb-md-2">
        <p class="text-bold text-dark" translate>Document actions</p>
        <div class="btn-toolbar" role="toolbar">
          <div class="btn-group mr-1" role="group" *ngIf="!slectedDocumentStatusChanged">
            <button
              type="button"
              [class]="'btn btn-' + (selectedDocument.ApproveStatuses[documentStatusI].ApproveStatus == 1 ? 'primary' : 'outline-primary')"
              (click)="setDocumentStatus(1)"
              translate
            >Approve document</button>
            <button
              type="button"
              [class]="'btn btn-' + (selectedDocument.ApproveStatuses[documentStatusI].ApproveStatus == 2 ? 'warning' : 'outline-warning')"
              (click)="setDocumentStatus(2)"
              translate
            >Reject document</button>
          </div>
          <div class="input-group" *ngIf="slectedDocumentStatusChanged" role="group">
            <div class="input-group-prepend">
              <button
                type="button"
                class="btn btn-secondary"
                (click)="setDocumentStatus(selectedDocumentOriginalStatus.ApproveStatus)"
                translate
              >Cancel</button>
            </div>
            <input *ngIf="selectedDocument.ApproveStatuses[documentStatusI].ApproveStatus == 2" type="text" class="form-control"
              placeholder="Reject reasone" aria-label="Reject reasone" [(ngModel)]="selectedDocument.ApproveStatuses[documentStatusI].RejectReason">
            <div class="input-group-append">
              <button
                tooltip="{{ 'The reject reason is required' | translate }}" container="body"
                [isDisabled]="selectedDocument.ApproveStatuses[documentStatusI].ApproveStatus != 2 || selectedDocument.ApproveStatuses[documentStatusI].RejectReason.length"
                triggers="pointerenter:pointerout"
                [disabled]="selectedDocument.ApproveStatuses[documentStatusI].ApproveStatus == 2 && !selectedDocument.ApproveStatuses[documentStatusI].RejectReason.length" type="button"
                [class]="'btn btn-' + (selectedDocument.ApproveStatuses[documentStatusI].ApproveStatus == 2 ? 'warning' : 'primary')"
                (click)="updateDocumentStatus(selectedDocument)"
              >{{ selectedDocument.ApproveStatuses[documentStatusI].ApproveStatus == 2 ? ('Reject this document'|translate) : ('Approve this document'|translate) }}</button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="selectedDocument.Description" class="mt-md-2 mb-md-2">
        <p class="text-bold text-dark" translate>Document description</p>
        <div class="p-2 bd-highlight pre">{{ selectedDocument.Description }}</div>
      </div>
      <div>
        <br />
        <figure class="figure" style="width: 100%; min-width: 500px;">
          <app-file-viewer 
            [filename]="getDocument(selectedDocument.Documents).FileName"
            [httpReq]="fileReq(getDocument(selectedDocument.Documents))"
            [id]="getDocument(selectedDocument.Documents).FileRef"
          ></app-file-viewer>
        </figure>
        <div class="card-body">
          <nav
            *ngIf="selectedDocument.Documents ? selectedDocument.Documents.length > 1 : false"
            aria-label="Page navigation example"
          >
            <ul class="pagination">
              <li class="page-item">
                <button class="page-link" (click)="previousDocument()">&laquo;</button>
              </li>
              <li *ngFor="let file of selectedDocument.Documents; let i = index"
                [class]="'page-item' + (documentPage == i ? ' active' : '')">
                <button class="page-link" (click)="documentPage = i">{{ i+1 }}</button>
              </li>
              <li class="page-item">
                <button class="page-link"
                  (click)="nextDocument(selectedDocument.Documents ? selectedDocument.Documents.length : 0)">&raquo;</button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>
