/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file-uploader.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./file-uploader.component";
var styles_FileUploaderComponent = [i0.styles];
var RenderType_FileUploaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileUploaderComponent, data: {} });
export { RenderType_FileUploaderComponent as RenderType_FileUploaderComponent };
export function View_FileUploaderComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { input: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "file-uploader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["fileInput", 1]], null, 0, "input", [["accept", "image/*,application/pdf"], ["multiple", "true"], ["style", "display: none;"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.publish() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "input-box"]], null, [[null, "click"], [null, "drop"], [null, "dragover"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openFileSelector() !== false);
        ad = (pd_0 && ad);
    } if (("drop" === en)) {
        var pd_1 = (_co.onDrop($event) !== false);
        ad = (pd_1 && ad);
    } if (("dragover" === en)) {
        var pd_2 = (_co.onDragOver($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [["class", "font-weight-bold text-muted"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Click to selecte files or dragg them in here"]))], null, null); }
export function View_FileUploaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "file-uploader", [], null, null, null, View_FileUploaderComponent_0, RenderType_FileUploaderComponent)), i1.ɵdid(1, 49152, null, 0, i2.FileUploaderComponent, [], null, null)], null, null); }
var FileUploaderComponentNgFactory = i1.ɵccf("file-uploader", i2.FileUploaderComponent, View_FileUploaderComponent_Host_0, {}, { files: "files" }, []);
export { FileUploaderComponentNgFactory as FileUploaderComponentNgFactory };
