import { first } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../user/state/user.query";
import * as i2 from "../user/state/user.service";
import * as i3 from "@angular/router";
import * as i4 from "../../services/sms/service";
var AuthenticatedGuard = /** @class */ (function () {
    function AuthenticatedGuard(userQuery, userService, router, smsService) {
        this.userQuery = userQuery;
        this.userService = userService;
        this.router = router;
        this.smsService = smsService;
    }
    AuthenticatedGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        return new Promise((function (res) {
            _this.userQuery.isAuthenticated$.pipe(first()).subscribe(function (isAuthenticated) {
                if (!isAuthenticated) {
                    if (_this.smsService.tokenStorage.getKey()) {
                        _this.userService.userMe().then(function (user) {
                            res(true);
                        }).catch(function (e) {
                            _this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                            res(false);
                        });
                    }
                    else {
                        _this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                        res(false);
                    }
                }
                else {
                    /* Authenticated */
                    res(true);
                }
            });
        }));
    };
    AuthenticatedGuard.prototype.canActivateChild = function (next, state) {
        return this.canActivate(next, state);
    };
    AuthenticatedGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticatedGuard_Factory() { return new AuthenticatedGuard(i0.ɵɵinject(i1.UserQuery), i0.ɵɵinject(i2.UserService), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.SMSService)); }, token: AuthenticatedGuard, providedIn: "root" });
    return AuthenticatedGuard;
}());
export { AuthenticatedGuard };
