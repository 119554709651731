import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CommonLayoutComponent} from './common-layout/common-layout.component';
import {LoginComponent} from './login/login.component';
import {UiModule} from '../ui/ui.module';
import {RouterModule} from '@angular/router';
import {SidebarDirectives} from '../side-nav/side-nav.directive';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {UserModule} from '../user/user.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [
    CommonLayoutComponent,
    LoginComponent,
    SidebarDirectives
  ],
  exports: [
    CommonLayoutComponent,
    LoginComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    RouterModule,
    FontAwesomeModule,
    UserModule,
    TranslateModule,
  ]
})
export class LayoutModule {
}
