import { Directive, ElementRef, OnInit, Input } from '@angular/core';

declare var $: any; // JQuery

// sidebar dropdown
@Directive({
  selector: '[expand-menu]'
})
export class ExpandMenu implements OnInit {
  private el: ElementRef;

  @Input()
  public isExpanded = false;

  constructor(public input: ElementRef) {
    this.el = input;
    this.isExpanded = false;
  }

  ngOnInit(): any {
    $(this.el.nativeElement).click(() => {
      if ($(this.el.nativeElement).parent().hasClass('open')) {
        $(this.el.nativeElement).parent().children('.dropdown-menu').slideUp(200, () => {
          $(this.el.nativeElement).parent().removeClass('open');
        });
        this.isExpanded = false;
      } else {
        $(this.el.nativeElement).parent().parent().children('li.open').children('.dropdown-menu').slideUp(200);
        $(this.el.nativeElement).parent().parent().children('li.open').children('a').removeClass('open');
        $(this.el.nativeElement).parent().parent().children('li.open').removeClass('open');
        $(this.el.nativeElement).parent().children('.dropdown-menu').slideDown(200, () => {
          $(this.el.nativeElement).parent().addClass('open');
        });
        this.isExpanded = true;
      }
    });
  }
}

export const SidebarDirectives = [
  ExpandMenu
];
