<div *ngIf="expired && status !== 2">
  <span class="badge badge-warning mr-md-1" translate>Expired</span>
</div>
<div *ngIf="!expired && status === 0" class="d-flex flex-row align-items-center m-2">
  <span class="badge badge-warning mr-md-1" translate>Waiting for approval</span>
</div>
<div *ngIf="!expired && status === 1" class="d-flex flex-row align-items-center m-2">
  <span class="badge badge-primary mr-md-1" translate>Approved</span>
</div>
<div *ngIf="status === 2" class="d-flex flex-row align-items-center m-2">
  <span class="badge badge-warning mr-md-1" translate>Rejected</span>
</div>
