/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../loader/loader.component.ngfactory";
import * as i3 from "../loader/loader.component";
import * as i4 from "../../../../../node_modules/ng2-pdfjs-viewer/ng2-pdfjs-viewer.ngfactory";
import * as i5 from "ng2-pdfjs-viewer";
import * as i6 from "@angular/common";
import * as i7 from "../../../../../node_modules/@hallysonh/ngx-imageviewer/hallysonh-ngx-imageviewer.ngfactory";
import * as i8 from "@hallysonh/ngx-imageviewer";
import * as i9 from "@angular/platform-browser";
import * as i10 from "./file-viewer.component";
var styles_FileViewerComponent = [];
var RenderType_FileViewerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FileViewerComponent, data: {} });
export { RenderType_FileViewerComponent as RenderType_FileViewerComponent };
function View_FileViewerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "h5", [["translate", ""]], null, null, null, null, null)), i0.ɵdid(2, 8536064, null, 0, i1.TranslateDirective, [i1.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(-1, null, ["Loading..."])), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "app-loader", [], null, null, null, i2.View_LoaderComponent_0, i2.RenderType_LoaderComponent)), i0.ɵdid(7, 49152, null, 0, i3.LoaderComponent, [], null, null)], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); }, null); }
function View_FileViewerComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "p", [["class", "text-center"], ["translate", ""]], null, null, null, null, null)), i0.ɵdid(1, 8536064, null, 0, i1.TranslateDirective, [i1.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"], translateParams: [1, "translateParams"] }, null), i0.ɵpod(2, { "currentFile": 0 }), (_l()(), i0.ɵted(-1, null, ["File %currentFile%"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; var currVal_1 = _ck(_v, 2, 0, _co.filename); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_FileViewerComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["style", "display: block; padding-bottom: 40px;"]], [[4, "height", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [], [[4, "height", null]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "ng2-pdfjs-viewer", [], null, null, null, i4.View_PdfJsViewerComponent_0, i4.RenderType_PdfJsViewerComponent)), i0.ɵdid(3, 114688, [[3, 4], ["pdfView", 4]], 0, i5.PdfJsViewerComponent, [], { externalWindow: [0, "externalWindow"], showSpinner: [1, "showSpinner"], downloadFileName: [2, "downloadFileName"], openFile: [3, "openFile"], download: [4, "download"], pdfSrc: [5, "pdfSrc"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FileViewerComponent_4)), i0.ɵdid(5, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = false; var currVal_3 = true; var currVal_4 = _co.filename; var currVal_5 = false; var currVal_6 = true; var currVal_7 = _co.blobUrl; _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = (_co.filename && !_co.loading); _ck(_v, 5, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.imgHeight ? (_co.imgHeight - (_co.showDownload ? 100 : 0)) : 500) + "px"); _ck(_v, 0, 0, currVal_0); var currVal_1 = ((_co.imgHeight ? (_co.imgHeight - (_co.showDownload ? 150 : 50)) : 450) + "px"); _ck(_v, 1, 0, currVal_1); }); }
function View_FileViewerComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ngx-imageviewer", [["filetype", "image"]], null, null, null, i7.View_ɵb_0, i7.RenderType_ɵb)), i0.ɵdid(1, 4374528, [[2, 4], ["imageViewer", 4]], 0, i8.ɵb, [i9.DomSanitizer, i0.Renderer2, i8.ɵc, i8.IMAGEVIEWER_CONFIG], { src: [0, "src"], filetype: [1, "filetype"], height: [2, "height"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.blobUrl; var currVal_1 = "image"; var currVal_2 = (_co.imgHeight - (_co.showDownload ? 150 : 0)); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_FileViewerComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ngx-imageviewer", [["filetype", "image"]], null, null, null, i7.View_ɵb_0, i7.RenderType_ɵb)), i0.ɵdid(1, 4374528, [[2, 4], ["imageViewer", 4]], 0, i8.ɵb, [i9.DomSanitizer, i0.Renderer2, i8.ɵc, i8.IMAGEVIEWER_CONFIG], { src: [0, "src"], filetype: [1, "filetype"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.blobUrl; var currVal_1 = "image"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_FileViewerComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "p", [["class", "text-center"], ["translate", ""]], null, null, null, null, null)), i0.ɵdid(1, 8536064, null, 0, i1.TranslateDirective, [i1.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"], translateParams: [1, "translateParams"] }, null), i0.ɵpod(2, { "currentFile": 0 }), (_l()(), i0.ɵted(-1, null, ["File %currentFile%"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; var currVal_1 = _ck(_v, 2, 0, _co.filename); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_FileViewerComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["style", "height: 100%"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FileViewerComponent_6)), i0.ɵdid(2, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FileViewerComponent_7)), i0.ɵdid(4, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FileViewerComponent_8)), i0.ɵdid(6, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imgHeight; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.imgHeight; _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.filename && !_co.loading); _ck(_v, 6, 0, currVal_2); }, null); }
function View_FileViewerComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "p", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["translate", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 8536064, null, 0, i1.TranslateDirective, [i1.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(-1, null, ["Download the file"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 3, 0, currVal_0); }, null); }
function View_FileViewerComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "p", [["class", "text-center"], ["translate", ""]], null, null, null, null, null)), i0.ɵdid(1, 8536064, null, 0, i1.TranslateDirective, [i1.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"], translateParams: [1, "translateParams"] }, null), i0.ɵpod(2, { "currentFile": 0 }), (_l()(), i0.ɵted(-1, null, ["File %currentFile%"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; var currVal_1 = _ck(_v, 2, 0, _co.filename); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_FileViewerComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "p", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "label", [["class", "text-bold"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i0.ɵdid(6, 8536064, null, 0, i1.TranslateDirective, [i1.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(-1, null, ["Your browser is not able to display this file."])), (_l()(), i0.ɵeld(8, 0, null, null, 3, "p", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["translate", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(10, 8536064, null, 0, i1.TranslateDirective, [i1.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(-1, null, ["Click here to download the file"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FileViewerComponent_11)), i0.ɵdid(13, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ""; _ck(_v, 6, 0, currVal_1); var currVal_2 = ""; _ck(_v, 10, 0, currVal_2); var currVal_3 = (_co.filename && !_co.loading); _ck(_v, 13, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filename; _ck(_v, 3, 0, currVal_0); }); }
function View_FileViewerComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[1, 0], ["fileContainer", 1]], null, 8, "div", [["style", "display: block; height: calc(100% - 61px); min-height: 411px;"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FileViewerComponent_3)), i0.ɵdid(2, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FileViewerComponent_5)), i0.ɵdid(4, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FileViewerComponent_9)), i0.ɵdid(6, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FileViewerComponent_10)), i0.ɵdid(8, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isPDF && !_co.unknownType); _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_co.isPDF && !_co.unknownType); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.showDownload && !_co.unknownType); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.unknownType; _ck(_v, 8, 0, currVal_3); }, null); }
export function View_FileViewerComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { fileContainer: 0 }), i0.ɵqud(671088640, 2, { imageViewer: 0 }), i0.ɵqud(671088640, 3, { pdfView: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FileViewerComponent_1)), i0.ɵdid(4, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FileViewerComponent_2)), i0.ɵdid(6, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 4, 0, currVal_0); var currVal_1 = !_co.loading; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_FileViewerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-file-viewer", [], null, null, null, View_FileViewerComponent_0, RenderType_FileViewerComponent)), i0.ɵdid(1, 573440, null, 0, i10.FileViewerComponent, [], null, null), i0.ɵprd(256, null, i8.IMAGEVIEWER_CONFIG, i10.ɵ0, [])], null, null); }
var FileViewerComponentNgFactory = i0.ɵccf("app-file-viewer", i10.FileViewerComponent, View_FileViewerComponent_Host_0, { filename: "filename", httpReq: "httpReq", id: "id", imgHeight: "imgHeight", showDownload: "showDownload" }, {}, []);
export { FileViewerComponentNgFactory as FileViewerComponentNgFactory };
