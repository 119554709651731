import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { _ } from '../../../util/i18n';
import * as i0 from "@angular/core";
import * as i1 from "./user.store";
import * as i2 from "../../../services/sms/service";
import * as i3 from "../../../services/notification.service";
import * as i4 from "@angular/router";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./user.query";
var UserService = /** @class */ (function () {
    function UserService(store, smsService, notificationService, router, translate, user) {
        var _this = this;
        this.store = store;
        this.smsService = smsService;
        this.notificationService = notificationService;
        this.router = router;
        this.translate = translate;
        this.user = user;
        this.subs = [];
        this.user$ = this.user.activeUser$;
        var sub = this.user$.subscribe(function (user) {
            _this.setLanguage((user && user.Preferences && user.Preferences.Language ? user.Preferences.Language : ''));
        });
        this.subs.push(sub);
    }
    UserService.prototype.ngOnDestroy = function () {
        this.subs.forEach(function (sub) { return sub.unsubscribe(); });
    };
    UserService.prototype.setLanguage = function (language) {
        return ['en-GB', 'nl-NL'].includes(language)
            ? this.translate.use(language)
            : this.translate.use(this.translate.defaultLang);
    };
    UserService.prototype.userMe = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var r, user;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.smsService.user.userMe()];
                    case 1:
                        r = _a.sent();
                        user = r.data.Message;
                        this.store.set([user]);
                        this.store.setActive(user.ID);
                        return [2 /*return*/, user];
                }
            });
        });
    };
    UserService.prototype.userUpdate = function (form) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var out;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.smsService.user.userUpdate(form)];
                    case 1:
                        out = _a.sent();
                        if (out && out.data && out.data.Message) {
                            this.store.set([out.data.Message]);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.userActivate = function (form) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var out, _a, Key, RefreshToken, User;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.smsService.user.userActivate(form)];
                    case 1:
                        out = _b.sent();
                        _a = out.data.Message, Key = _a.Key, RefreshToken = _a.RefreshToken, User = _a.User;
                        this.smsService.tokenStorage.setKey(Key.Key);
                        this.smsService.tokenStorage.setSecret(Key.Secret);
                        this.smsService.tokenStorage.setRefreshToken(RefreshToken);
                        this.store.set([User]);
                        this.store.setActive(User.ID);
                        return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.userResetPassword = function (form) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var out, _a, Key, RefreshToken, User;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.smsService.user.userResetPasswordConfirm(form)];
                    case 1:
                        out = _b.sent();
                        _a = out.data.Message, Key = _a.Key, RefreshToken = _a.RefreshToken, User = _a.User;
                        this.smsService.tokenStorage.setKey(Key.Key);
                        this.smsService.tokenStorage.setSecret(Key.Secret);
                        this.smsService.tokenStorage.setRefreshToken(RefreshToken);
                        this.store.set([User]);
                        this.store.setActive(User.ID);
                        return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.userLogin = function (loginForm) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var r;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.smsService.user.userLogin(loginForm)];
                    case 1:
                        r = _a.sent();
                        return [2 /*return*/, this.setCredentials(r.data.Message)];
                }
            });
        });
    };
    UserService.prototype.setCredentials = function (data) {
        var user = data.User;
        this.smsService.tokenStorage.setKey(data.Key.Key);
        this.smsService.tokenStorage.setSecret(data.Key.Secret);
        this.smsService.tokenStorage.setRefreshToken(data.RefreshToken);
        this.notificationService.success(this.translate.instant(_('Successfully logged in as %emailAddress%'), { emailAddress: user.Email }));
        this.store.set([user]);
        this.store.setActive(user.ID);
        return user;
    };
    UserService.prototype.logout = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.smsService.tokenStorage.setKey(null);
                this.smsService.tokenStorage.setSecret(null);
                this.smsService.tokenStorage.setRefreshToken(null);
                this.notificationService.success(this.translate.instant(_('Successfully logged out')));
                this.store.reset();
                return [2 /*return*/, this.router.navigate(['/login'])];
            });
        });
    };
    UserService.prototype.SpecialDocuments = function (orgID) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var r, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(orgID)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.smsService.organization.orgSpecialDocuments(orgID)];
                    case 1:
                        _a = _b.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.smsService.user.userSpecialDocuments()];
                    case 3:
                        _a = _b.sent();
                        _b.label = 4;
                    case 4:
                        r = _a;
                        return [2 /*return*/, r.data.Message];
                }
            });
        });
    };
    UserService.prototype.UpdateSpecialDocument = function (orgID, specialDocumentID, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var r;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.smsService.user.updateSpecialDocument(orgID, specialDocumentID, data)];
                    case 1:
                        r = _a.sent();
                        return [2 /*return*/, r.data.Message];
                }
            });
        });
    };
    UserService.prototype.DeleteSpecialDocument = function (orgID, specialDocumentID) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.smsService.user.deleteSpecialDocument(orgID, specialDocumentID)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    UserService.prototype.CreateSpecialDocument = function (orgID, forRole, name, description, files) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var r;
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, (_a = this.smsService.user).createSpecialDocument.apply(_a, tslib_1.__spread([orgID, "" + forRole, name, description], files))];
                    case 1:
                        r = _b.sent();
                        return [2 /*return*/, r.data.Message];
                }
            });
        });
    };
    UserService.prototype.GetSpecialDocumentFile = function (specialDocumentID, fileID) {
        return this.smsService.user.specialDocumentFile(specialDocumentID, fileID, { responseType: 'blob' });
    };
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.UserStore), i0.ɵɵinject(i2.SMSService), i0.ɵɵinject(i3.NotificationService), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i5.TranslateService), i0.ɵɵinject(i6.UserQuery)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
