import { ToastDefaults } from 'ng-snotify';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http);
}
var ɵ0 = ToastDefaults;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
