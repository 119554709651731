<div *ngIf="loading">
  <h5 translate>Loading...</h5>
  <div class="card-body">
    <div class="text-center">
      <app-loader></app-loader>
    </div>
  </div>
</div>
<div *ngIf="!loading" style="display: block; height: calc(100% - 61px); min-height: 411px;" #fileContainer>
  <div 
    *ngIf="isPDF && !unknownType" 
    style="display: block; padding-bottom: 40px;"
    [style.height]="(imgHeight ? (imgHeight - (showDownload ? 100 : 0)) : 500) + 'px'" 
  >
    <div [style.height]="(imgHeight ? (imgHeight - (showDownload ? 150 : 50)) : 450) + 'px'">
      <ng2-pdfjs-viewer 
        #pdfView 
        [pdfSrc]="blobUrl"
        [download]="true"
        [downloadFileName]="filename"
        [openFile]="false"
        [externalWindow]="false"
        [showSpinner]="true"
      ></ng2-pdfjs-viewer>
    </div>
    <p 
      class="text-center" 
      *ngIf="filename && !loading" 
      translate 
      [translateParams]="{'currentFile': filename}"
    >File %currentFile%</p>
  </div>
  <div *ngIf="!isPDF && !unknownType" style="height: 100%">
    <ngx-imageviewer
      *ngIf="imgHeight"
      [height]="imgHeight - (showDownload ? 150 : 0)"
      [src]="blobUrl"
      filetype="image"
      #imageViewer
    ></ngx-imageviewer>
    <ngx-imageviewer
      *ngIf="!imgHeight"
      [src]="blobUrl"
      filetype="image"
      #imageViewer
    ></ngx-imageviewer>
    <p 
      class="text-center" 
      translate 
      [translateParams]="{'currentFile': filename}" 
      *ngIf="filename && !loading"
    >File %currentFile%</p>
  </div>
  <div *ngIf="showDownload && !unknownType">
    <p class="text-center">
      <button class="btn btn-primary" type="button" (click)="downloadFile()" translate>Download the file</button>
    </p>
  </div>
  <div *ngIf="unknownType">
    <p class="text-center">
      <label class="text-bold">{{ filename }}</label><br/>
      <span translate>Your browser is not able to display this file.</span>
    </p>
    <p class="text-center">
      <button class="btn btn-primary" type="button" (click)="downloadFile()" translate>Click here to download the file</button>
    </p>
    <p 
      class="text-center" 
      *ngIf="filename && !loading" 
      translate 
      [translateParams]="{'currentFile': filename}"
    >File %currentFile%</p>
  </div>
</div>
