import {Component} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {environment} from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'sms-frontend';

  public constructor(private titleService: Title ) {
    if (environment.whitelabel && environment.whitelabel.productName) {
      this.titleService.setTitle(environment.whitelabel.productName);
    }
  }
}
