import { Component, Input, EventEmitter, Output } from '@angular/core';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.css']
})
export class FileListComponent {
  faPlus = faPlus;
  faMinus = faMinus;

  @Input()
  inputFiles: File[];

  @Input()
  addButton: boolean; // The plus sign behind the input

  @Input()
  hideActionButton: boolean; // Removes the plus or minus button if true

  @Output()
  actionButtonClicked = new EventEmitter<number>(); // If a line it's plus or minus icon was pressed

  getFiles() {
    return Object.keys(this.inputFiles).map(key => this.inputFiles[key]);
  }

  iconClick(index: number) {
    this.actionButtonClicked.emit(index);
  }
}
