export const environment = {
  production: false,
  apiUrl: 'https://api.test.sms.klippa.com',
  whitelabel: {
    logo: undefined,
    name: 'Klippa',
    productName: 'Klippa SMS',
    loginBG: undefined,
    registerBG: undefined
  },
  support_email: 'installationsupport@nedcon.com',
};
