<div *ngIf="!loading" class="modal-header">
  <div class="d-flex align-items-center">
    <h5 *ngIf="!isSupervisor" class="modal-title" translate [translateParams]="{'name': worker.Name}">Edit worker: %name%</h5>
    <h5 *ngIf="isSupervisor" class="modal-title" translate [translateParams]="{'name': worker.Name}">View worker: %name%</h5>
    <worker-status [status]="worker.Status"></worker-status>
  </div>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div *ngIf="!loading" class="container">
  <div class="row">
    <div class="col-md" *ngIf="!isSubcontractor">
      <div class="modal-body" *ngIf="!isSubcontractor">
        <label class="text-bold text-dark" translate>Positions</label>
        <div *ngIf="worker.Positions && !!worker.Positions.length" class="d-flex flex-row align-items-center m-2">
          <span *ngFor="let position of worker.Positions as positions" class="badge badge-primary mr-md-1">
            {{position.Name}}
          </span>
        </div>
        <p *ngIf="!worker.Positions || !worker.Positions.length" translate>This worker doesn't have any positions</p>
      </div>
      <div class="modal-body" *ngIf="!isSubcontractor && requiredDocuments && requiredDocuments.length">
        <label class="text-bold text-dark" translate>Missing documents</label>
        <div class="d-flex flex-row align-items-center m-2">
          <span class="m-1 badge badge-danger" *ngFor="let document of requiredDocuments; index as i">{{document.Name}}</span>
        </div>
      </div>
    </div>
    <div class="col-md" *ngIf="isSubcontractor">
      <!-- Left side -->
      <div class="modal-body">
        <form (ngSubmit)="updateWorkerPositions()">
          <app-form-group>
            <label class="text-bold text-dark" for="positions" translate>Positions</label>
            <ng-select
              *ngIf="modeIsProject"
              [items]="project.ProjectPositions"
              bindLabel="Name"
              name="positions"
              id="positions"
              [multiple]="true"
              [clearable]="false"
              [(ngModel)]="worker.Positions"
            ></ng-select>
              <ng-select
                *ngIf="modeIsDashboard"
                [items]="availablePositionsList"
                bindLabel="Name"
                name="positions"
                id="positions"
                groupBy="ProjectName"
                [multiple]="true"
                [clearable]="false"
                [(ngModel)]="worker.Positions"
              >
              <ng-template ng-optgroup-tmp let-item="item">{{item.ProjectName}}</ng-template>
            </ng-select>
          </app-form-group>
          <button type="submit" class="btn btn-primary" translate>Save position</button>
          <form-error [errs]="GeneralErr"></form-error>
        </form>
      </div>
      <form (ngSubmit)="addWorkerDocument()" class="modal-body">
        <app-form-group>
          <label class="text-bold text-dark" translate>Add worker document</label><br/>
          <div *ngIf="requiredDocuments.length">
            <label translate>Missing document types:</label>
            <span class="m-1 badge badge-danger" *ngFor="let document of requiredDocuments; index as i">{{document.Name}}</span><br/>
          </div>
          <label class="text-dark" translate>Files</label>
          <file-uploader
            (files)="addDocumentFileUploadEv($event)"
          ></file-uploader>

          <p *ngIf="selectedAndNormalHaveFiles" class="text-dark" translate>Files uploaded but no action taken</p>
          <file-list
            [inputFiles]="workerDocumentUpload.Files"
            [hideActionButton]="allFilesLength <= 1"
            [addButton]="true"
            (actionButtonClicked)="addDocumentToSelected($event)"
          ></file-list>

          <p *ngIf="selectedAndNormalHaveFiles" class="text-dark" translate>Selected files to upload</p>
          <file-list
            [inputFiles]="workerDocumentUpload.SelectedFiles"
            (actionButtonClicked)="removeFromSelected($event)"
          ></file-list>

          <label class="text-dark" translate>Document type</label>
          <ng-select
            [items]="allDocuments"
            bindLabel="Name"
            name="requiredDocuments"
            id="requiredDocuments"
            [clearable]="false"
            [(ngModel)]="workerDocumentUpload.DocumentType"
          ></ng-select>
          <div class="pt-2" *ngIf="newDocumentExpires(workerDocumentUpload.DocumentType)">
            <label *ngIf="newDocumentExpires(workerDocumentUpload.DocumentType)" class="text-dark" translate>Expires on</label>
            <input
              class="input-box form-control"
              placeholder="Select a expire date"
              angular-mydatepicker
              name="mydate"
              (click)="dp.toggleCalendar()"
              [(ngModel)]="selectedDate"
              [options]="myDpOptions"
              #dp="angular-mydatepicker"/>
          </div>
          <div class="pt-2" *ngIf="workerDocumentUpload.DocumentType && workerDocumentUpload.DocumentType.DescriptionRequired !== 2">
            <label class="text-dark"><span translate>Description</span>&nbsp;<b *ngIf="!workerDocumentUpload.DocumentType.DescriptionRequired" translate>(not required)</b></label>
              <textarea
              type="text"
              class="form-control"
              placeholder="{{ 'Enter any extra information about the document type' | translate }}"
              id="description"
              name="description"
              [(ngModel)]="workerDocumentUpload.Description"
            ></textarea>
          </div>
        </app-form-group>
        <button
          [disabled]="selectedDocumentsToUploadLength === 0 || selectedDocumentsToUploadLength > 17"
          tooltip="{{ selectedDocumentsToUploadLength === 0 ? ('No files to upload' | translate) : ('You can\'t upload more than 17 files' | translate) }}" container="body"
          [isDisabled]="selectedDocumentsToUploadLength !== 0 && selectedDocumentsToUploadLength <= 17"
          triggers="pointerenter:pointerout"
          type="submit"
          class="btn btn-primary"
        >{{ 'Save %amount% document(s)' | translate: {amount: selectedDocumentsToUploadLength} }}</button>
        <form-error [errs]="workerDocumentUpload.GeneralErr"></form-error>
      </form>
    </div>
    <div class="col-md">
      <!-- Right side -->
      <form class="modal-body">
        <app-form-group>
          <label class="text-bold text-dark" translate>Documents of worker</label>
          <document-list
            [worker]="worker"
            (updateData)="getWorkerInfo()"
            [project]="project"
          ></document-list>
        </app-form-group>
      </form>
    </div>
  </div>
</div>
