import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { User } from '../../../services/sms';
import { Injectable } from '@angular/core';

export interface UserState extends EntityState<User>, ActiveState {
}

export const initialState: UserState = {
  active: null,
};

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'user', idKey: 'ID', resettable: true })
export class UserStore extends EntityStore<UserState, User> {
  constructor() {
    super(initialState);
  }
}
