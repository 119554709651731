import * as tslib_1 from "tslib";
import { TranslateDefaultParser } from '@ngx-translate/core';
export function _(str) {
    /* Function to mark a string for translation. */
    return str;
}
var CustomParser = /** @class */ (function (_super) {
    tslib_1.__extends(CustomParser, _super);
    function CustomParser() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /* Custom parser in order to change the RegEx used for string interpolation. */
        /* Change from {{placeholder}} to %placeholder% */
        /* Author: Mark */
        _this.templateMatcher = /%\s?([^%\s]*)\s?%/g;
        return _this;
    }
    return CustomParser;
}(TranslateDefaultParser));
export { CustomParser };
var CustomHandler = /** @class */ (function () {
    function CustomHandler() {
    }
    /* Custom handler for missing translations in order to also perform string interpolation on non-translated keys. */
    /* Author: Mark */
    CustomHandler.prototype.handle = function (params) {
        var translateService = params.translateService;
        return translateService.parser.interpolate(params.key, params.interpolateParams);
    };
    return CustomHandler;
}());
export { CustomHandler };
