<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ng-container [ngSwitch]="type">
    <p *ngSwitchCase="'text'" [innerHTML]="getMessage()"></p>

    <div class="form-group" *ngSwitchCase="'password'">
      <label for="value" class="" [innerHTML]="getMessage()"></label>
      <input type="password" class="form-control" id="value" name="value" [(ngModel)]="value">
    </div>

  </ng-container>

</div>
<div class="modal-footer">
  <button class="btn btn-default btn-sm" (click)="dismiss()" *ngIf="showCancel" translate>Cancel</button>
  <button class="btn btn-primary btn-sm" (click)="confirm()">{{ confirmLabel ? confirmLabel : ('Ok' | translate) }}</button>
</div>
