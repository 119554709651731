import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap';
import { RouterModule } from '@angular/router';
import { UserOrgRolesPipe } from './pipes/user-org-roles.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { UserRolePickerComponent } from './components/user-role-picker/user-role-picker.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    UserRolePickerComponent,
    UserOrgRolesPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    BsDropdownModule,
    RouterModule,
    TranslateModule,
    NgSelectModule
  ],
  exports: [
    UserRolePickerComponent,
    UserOrgRolesPipe,
  ]
})
export class UserModule {
}
