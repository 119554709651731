import { first } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../user/state/user.query";
import * as i2 from "../user/state/user.service";
import * as i3 from "../../services/sms/service";
var CurrentUserResolver = /** @class */ (function () {
    function CurrentUserResolver(userQuery, userService, smsService) {
        this.userQuery = userQuery;
        this.userService = userService;
        this.smsService = smsService;
    }
    CurrentUserResolver.prototype.resolve = function (route) {
        var _this = this;
        return new Promise((function (resolve) {
            _this.userQuery.isAuthenticated$.pipe(first()).subscribe(function (isAuthenticated) {
                if (!isAuthenticated && _this.smsService.tokenStorage.getKey()) {
                    _this.userService.userMe().then(function (user) {
                        resolve(user);
                    }).catch(function (e) {
                        resolve(null);
                    });
                }
                else {
                    /* Authenticated */
                    resolve(null);
                }
            });
        }));
    };
    CurrentUserResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CurrentUserResolver_Factory() { return new CurrentUserResolver(i0.ɵɵinject(i1.UserQuery), i0.ɵɵinject(i2.UserService), i0.ɵɵinject(i3.SMSService)); }, token: CurrentUserResolver, providedIn: "root" });
    return CurrentUserResolver;
}());
export { CurrentUserResolver };
