import {
  DocumentTypeApi,
  OrganizationApi,
  ProjectApi,
  SubcontractorApi,
  UserApi,
  WorkerApi,
} from './api';
import { Configuration } from './configuration';
import { AuthManager, LocalTokenStorage } from './auth';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { environment } from '../../../environments/environment';
import Axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class SMSService {
  protected configuration: Configuration;

  public tokenStorage: LocalTokenStorage;
  public documentType: DocumentTypeApi;
  public organization: OrganizationApi;
  public project: ProjectApi;
  public subcontractor: SubcontractorApi;
  public user: UserApi;
  public worker: WorkerApi;

  private refreshPromise: Promise<any>;

  constructor(public localStorageService: LocalStorageService) {
    this.tokenStorage = new LocalTokenStorage(localStorageService, '');
    const authManager = new AuthManager(this.tokenStorage);

    const BASE_PATH = environment.apiUrl.replace(/\/+$/, '') + '/api/v1';
    const axiosInstance = Axios.create();

    const skipAuthPaths = [
      '/api/v1/user/login',
      '/api/v1/user/activate',
      '/api/v1/user/resetPassword/request',
      '/api/v1/user/resetPassword/confirm',
    ];

    axiosInstance.interceptors.request.use(config => {
      const url = config.url;

      // Skip auth headers for certain calls.
      if (skipAuthPaths.includes(url)) {
        return config;
      }

      if (url.includes('/api/v1/subcontractor/validCreateKey/') !== false) {
        return config;
      }

      if (url.includes('/api/v1/subcontractor/createNewFromInviteLink') !== false) {
        return config;
      }

      config.headers = { ...config.headers, ...authManager.getAuthHeaders() };
      return config;
    }, error => {
      // Do something with request error
      return Promise.reject(error);
    });

    const vm = this;

    axiosInstance.interceptors.response.use(undefined, async err => {
      // Try to refresh token when this is an error about TokenExpired.
      if (vm.isTokenExpiredResponse(err)) {
        if (vm.refreshPromise == null) {
          const data = { RefreshToken: authManager!.getTokenStorage().getRefreshToken() };
          vm.refreshPromise = vm.user.userRefreshToken(data).then(refreshedKey => {
            const key = refreshedKey.data.Message.NewKey.Key;
            const secret = refreshedKey.data.Message.NewKey.Secret;
            const refreshToken = refreshedKey.data.Message.RefreshToken;
            if (!key || !secret || !refreshToken) {
              return Promise.reject('Did not receive new key');
            }

            vm.tokenStorage.setKey(key);
            vm.tokenStorage.setSecret(secret);
            vm.tokenStorage.setRefreshToken(refreshToken);

            this.refreshPromise = null;
            return Promise.resolve();
          }).catch(refreshErr => {
            this.refreshPromise = null;
            return Promise.reject(refreshErr);
          });
        }

        return vm.refreshPromise.then(() => {
          err.config.__isRetryRequest = true;
          return axiosInstance(err.config);
        }).catch((refreshErr) => {
          return Promise.reject(refreshErr);
        });
      }
      throw err;
    });

    // axiosInstance.interceptors.response.use(response => {
    //   return response;
    // }, error => {
    //   // Do something with response error
    //   return Promise.reject(error);
    // });

    this.configuration = new Configuration({ authManager });

    this.documentType = new DocumentTypeApi(this.configuration, BASE_PATH, axiosInstance);
    this.organization = new OrganizationApi(this.configuration, BASE_PATH, axiosInstance);
    this.project = new ProjectApi(this.configuration, BASE_PATH, axiosInstance);
    this.subcontractor = new SubcontractorApi(this.configuration, BASE_PATH, axiosInstance);
    this.user = new UserApi(this.configuration, BASE_PATH, axiosInstance);
    this.worker = new WorkerApi(this.configuration, BASE_PATH, axiosInstance);
  }

  isTokenExpiredResponse(err): boolean {
    return (
      (err.config && err.config.__isRetryRequest)
      || !err.response
      || err.response.status !== 401
      || typeof (err.response.data) !== 'object'
      || !err.response.data.ErrorCode
      || err.response.data.ErrorCode !== 'AuthTokenExpired'
      || !this.tokenStorage.getKey()
      || !this.tokenStorage.getSecret()
    ) ? false : true;
  }
}

