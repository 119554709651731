import * as tslib_1 from "tslib";
import * as i0 from "@angular/core";
import * as i1 from "ng-snotify";
import * as i2 from "@ngx-translate/core";
var NotificationService = /** @class */ (function () {
    function NotificationService(snotifyService, translateService) {
        this.snotifyService = snotifyService;
        this.translateService = translateService;
    }
    NotificationService.prototype.translate = function (input) {
        return input ? this.translateService.instant(input) : input;
    };
    NotificationService.prototype.notify = function (body, title, config) {
        if (title === void 0) { title = null; }
        if (config === void 0) { config = null; }
        return this.snotifyService.simple(body, title, tslib_1.__assign({ showProgressBar: false }, config));
    };
    NotificationService.prototype.notifyTranslated = function (body, title, config) {
        if (title === void 0) { title = null; }
        if (config === void 0) { config = null; }
        return this.notify(this.translate(body), this.translate(title), config);
    };
    NotificationService.prototype.success = function (body, title, config) {
        if (title === void 0) { title = null; }
        if (config === void 0) { config = null; }
        return this.snotifyService.success(body, title, tslib_1.__assign({ showProgressBar: false }, config));
    };
    NotificationService.prototype.successTranslated = function (body, title, config) {
        if (title === void 0) { title = null; }
        if (config === void 0) { config = null; }
        return this.success(this.translate(body), this.translate(title), config);
    };
    NotificationService.prototype.info = function (body, title, config) {
        if (title === void 0) { title = null; }
        if (config === void 0) { config = null; }
        return this.snotifyService.info(body, title, tslib_1.__assign({ showProgressBar: false }, config));
    };
    NotificationService.prototype.infoTranslated = function (body, title, config) {
        if (title === void 0) { title = null; }
        if (config === void 0) { config = null; }
        return this.info(this.translate(body), this.translate(title), config);
    };
    NotificationService.prototype.warning = function (body, title, config) {
        if (title === void 0) { title = null; }
        if (config === void 0) { config = null; }
        return this.snotifyService.warning(body, title, tslib_1.__assign({ showProgressBar: false }, config));
    };
    NotificationService.prototype.warningTranslated = function (body, title, config) {
        if (title === void 0) { title = null; }
        if (config === void 0) { config = null; }
        return this.warning(this.translate(body), this.translate(title), config);
    };
    NotificationService.prototype.error = function (body, title, config) {
        if (title === void 0) { title = null; }
        if (config === void 0) { config = null; }
        return this.snotifyService.error(body, title, tslib_1.__assign({ showProgressBar: false }, config));
    };
    NotificationService.prototype.errorTranslated = function (body, title, config) {
        if (title === void 0) { title = null; }
        if (config === void 0) { config = null; }
        return this.error(this.translate(body), this.translate(title), config);
    };
    NotificationService.prototype.confirm = function (body, title, config) {
        if (title === void 0) { title = null; }
        if (config === void 0) { config = null; }
        return this.snotifyService.confirm(body, title, tslib_1.__assign({ showProgressBar: false }, config));
    };
    NotificationService.prototype.confirmTranslated = function (body, title, config) {
        if (title === void 0) { title = null; }
        if (config === void 0) { config = null; }
        return this.confirm(this.translate(body), this.translate(title), config);
    };
    NotificationService.prototype.prompt = function (body, title, config) {
        if (title === void 0) { title = null; }
        if (config === void 0) { config = null; }
        return this.snotifyService.prompt(body, title, tslib_1.__assign({ showProgressBar: false }, config));
    };
    NotificationService.prototype.promptTranslated = function (body, title, config) {
        if (title === void 0) { title = null; }
        if (config === void 0) { config = null; }
        return this.prompt(this.translate(body), this.translate(title), config);
    };
    NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.ɵɵinject(i1.SnotifyService), i0.ɵɵinject(i2.TranslateService)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };
