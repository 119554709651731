import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticatedGuard } from './modules/authenticated/authenticated.guard';
import { CommonLayoutComponent } from './modules/layout/common-layout/common-layout.component';
import { LoginComponent } from './modules/layout/login/login.component';
import { CurrentUserResolver } from './modules/authentication/currentUser.resolver';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '',
    component: LoginComponent,
    loadChildren: './modules/authentication/authentication.module#AuthenticationModule',
    resolve: {
      currentUser: CurrentUserResolver,
    }
  },
  {
    path: '',
    component: CommonLayoutComponent,
    loadChildren: './modules/authenticated/authenticated.module#AuthenticatedModule',
    canActivate: [AuthenticatedGuard]
  },
  {
    path: '**',
    redirectTo: '/login',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
