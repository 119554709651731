import * as tslib_1 from "tslib";
import { EntityStore, StoreConfig } from '@datorama/akita';
import * as i0 from "@angular/core";
export var initialState = {
    active: null,
};
var UserStore = /** @class */ (function (_super) {
    tslib_1.__extends(UserStore, _super);
    function UserStore() {
        return _super.call(this, initialState) || this;
    }
    UserStore.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserStore_Factory() { return new UserStore(); }, token: UserStore, providedIn: "root" });
    UserStore = tslib_1.__decorate([
        StoreConfig({ name: 'user', idKey: 'ID', resettable: true })
    ], UserStore);
    return UserStore;
}(EntityStore));
export { UserStore };
