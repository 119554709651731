import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserQuery } from '../../user/state/user.query';
import { userHasRole, UserRole } from '../../user/user.util';
import { Observable } from 'rxjs/Rx';
import {
  IconDefinition,
  faArrowCircleLeft,
  faAngleRight,
  faHome,
  faUserCog,
  faBars,
  faFolder,
  faCogs,
  faPowerOff,
  faCaretDown,
  faCrown,
  faAngleDown,
  faBuilding,
  faQuestion,
  faInfo,
  faFileAlt,
} from '@fortawesome/free-solid-svg-icons';
import { User } from 'src/app/services/sms';
import { UserService } from '../../user/state/user.service';
import { SMSService } from '../../../services/sms/service';
import { _ } from '../../../util/i18n';
import { NotificationService } from '../../../services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

declare interface Link {
  requiredPermissions?: UserRole;
  to: string;
  icon?: IconDefinition;
  title: string;
  subItems?: Link[];
  isExpanded?: boolean;
}

@Component({
  selector: 'app-common-layout',
  templateUrl: './common-layout.component.html',
  styleUrls: ['./common-layout.component.css']
})

export class CommonLayoutComponent implements OnInit, OnDestroy {
  faArrowCircleLeft = faArrowCircleLeft;
  faAngleRight = faAngleRight;
  faAlignDown = faAngleDown;
  faPowerOff = faPowerOff;
  faCaretDown = faCaretDown;
  faBars = faBars;
  faQuestion = faQuestion;
  faInfo = faInfo;

  availableLinks: Link[] = [
    {
      icon: faHome,
      title: _('Dashboard'),
      to: '/dashboard',
    }, {
      icon: faFolder,
      title: _('Projects'),
      to: '/projects',
      requiredPermissions: UserRole.AllProjects,
    }, {
      icon: faFileAlt,
      title: _('Resources'),
      to: '/documents',
      requiredPermissions: (UserRole.All ^ UserRole.OrgAdmin) ^ UserRole.Admin,
    }, {
      icon: faUserCog,
      title: _('Settings'),
      to: '/settings',
    }, {
      requiredPermissions: UserRole.OrgAdmin,
      icon: faBuilding,
      title: _('Company'),
      to: '/dashboard',
      subItems: [
        {
          requiredPermissions: UserRole.OrgAdmin,
          title: _('Users'),
          to: '/company/users/my',
        },
        {
          requiredPermissions: UserRole.OrgAdmin,
          title: _('Subcontractors'),
          to: '/company/subcontractors/my',
        },
        {
          requiredPermissions: UserRole.OrgAdmin,
          title: _('Document types'),
          to: '/company/documentTypes/my',
        },
        {
          requiredPermissions: UserRole.OrgAdmin,
          title: _('Resources'),
          to: '/company/manage/documents/my',
        }
      ],
    }, {
      requiredPermissions: UserRole.Admin,
      icon: faCrown,
      title: _('Admin'),
      to: '/dashboard',
      subItems: [
        {
          requiredPermissions: UserRole.Admin,
          title: _('Companies'),
          to: '/companies',
        },
        {
          requiredPermissions: UserRole.Admin,
          title: _('Subcontractors'),
          to: '/subcontractors',
        },
        {
          requiredPermissions: UserRole.Admin,
          title: _('Global document types'),
          to: '/documentTypes',
        },
      ],
    }, {
      requiredPermissions: UserRole.SubcontractorAdmin,
      icon: faCogs,
      title: _('Subcontractor settings'),
      to: '/subcontractor/my',
    }
  ];

  public app: any;
  public links: Link[];
  public headerThemes: any;
  public changeHeader: any;
  public sidenavThemes: any;
  public changeSidenav: any;
  public headerSelected: any;
  public sidenavSelected: any;
  public searchActived: any;
  public searchModel: any;
  public user$: Observable<User>;
  private subs: Subscription[] = [];
  public environment = environment;

  constructor(
    public user: UserQuery,
    public userService: UserService,
    protected smsService: SMSService,
    protected notificationService: NotificationService,
    protected translate: TranslateService
  ) {
    this.app = {
      layout: {
        sidePanelOpen: false,
        isMenuOpened: true,
        isMenuCollapsed: false,
        themeConfigOpen: false,
        rtlActived: false,
        searchActived: false
      }
    };

    this.links = [];

    this.headerThemes = ['header-default', 'header-primary', 'header-info', 'header-success', 'header-danger', 'header-dark'];
    this.changeHeader = changeHeader;

    function changeHeader(headerTheme) {
      this.headerSelected = headerTheme;
    }

    this.sidenavThemes = ['sidenav-default', 'side-nav-dark'];
    this.changeSidenav = changeSidenav;

    function changeSidenav(sidenavTheme) {
      this.sidenavSelected = sidenavTheme;
    }
  }


  ngOnInit() {
    this.user$ = this.user.activeUser$;
    const sub = this.user$.subscribe(user => {
      this.links = this.availableLinks.filter(link => {
        if (link.requiredPermissions === undefined) {
          return true;
        }
        return userHasRole(user, link.requiredPermissions);
      });

      for (const i in this.links) {
        if (this.links[i].subItems && this.links[i].subItems.length > 0) {
          this.links[i].subItems = this.links[i].subItems.filter(link => {
            if (link.requiredPermissions === undefined) {
              return true;
            }
            return userHasRole(user, link.requiredPermissions);
          });
        }
      }
    });
    this.subs.push(sub);
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  resendActivationMail() {
    this.smsService.user.userValidateResend().then(() => {
      const title = _('The validation email has successfully been sent. Please check your email account.');
      this.notificationService.successTranslated(title, null, { timeout: 0 });
    });
  }
}
