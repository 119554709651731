/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-bootstrap/tooltip";
import * as i3 from "ngx-bootstrap/component-loader";
import * as i4 from "ngx-bootstrap/positioning";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i7 from "@fortawesome/angular-fontawesome";
import * as i8 from "@angular/platform-browser";
import * as i9 from "@angular/common";
import * as i10 from "./file-list.component";
var styles_FileListComponent = [i0.styles];
var RenderType_FileListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileListComponent, data: {} });
export { RenderType_FileListComponent as RenderType_FileListComponent };
function View_FileListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "th", [["scope", "col"]], null, null, null, null, null))], null, null); }
function View_FileListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "span", [["class", "clickable"]], [[1, "aria-describedby", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.iconClick(_v.parent.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 212992, null, 0, i2.TooltipDirective, [i1.ViewContainerRef, i3.ComponentLoaderFactory, i2.TooltipConfig, i1.ElementRef, i1.Renderer2, i4.PositioningService], { tooltip: [0, "tooltip"] }, null), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i6.View_FaIconComponent_0, i6.RenderType_FaIconComponent)), i1.ɵdid(4, 573440, null, 0, i7.FaIconComponent, [i8.DomSanitizer, i7.FaConfig, i7.FaIconLibrary, [2, i7.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("Click to only upload only some files for this document type")), ""); _ck(_v, 1, 0, currVal_1); var currVal_4 = _co.faPlus; _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).ariaDescribedby; _ck(_v, 0, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 4).title; var currVal_3 = i1.ɵnov(_v, 4).renderedIconHTML; _ck(_v, 3, 0, currVal_2, currVal_3); }); }
function View_FileListComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "clickable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.iconClick(_v.parent.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i6.View_FaIconComponent_0, i6.RenderType_FaIconComponent)), i1.ɵdid(2, 573440, null, 0, i7.FaIconComponent, [i8.DomSanitizer, i7.FaConfig, i7.FaIconLibrary, [2, i7.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.faMinus; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).title; var currVal_1 = i1.ɵnov(_v, 2).renderedIconHTML; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_FileListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "td", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileListComponent_5)), i1.ɵdid(2, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["elseBlock", 2]], null, 0, null, View_FileListComponent_6))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.addButton; var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_FileListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "th", [["scope", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileListComponent_4)), i1.ɵdid(6, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.hideActionButton; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (_v.context.index + 1); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_1); }); }
function View_FileListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "table", [["class", "table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "th", [["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["#"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "th", [["scope", "col"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(6, 8536064, null, 0, i5.TranslateDirective, [i5.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["File name"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileListComponent_2)), i1.ɵdid(9, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileListComponent_3)), i1.ɵdid(12, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 6, 0, currVal_0); var currVal_1 = !_co.hideActionButton; _ck(_v, 9, 0, currVal_1); var currVal_2 = _co.getFiles(); _ck(_v, 12, 0, currVal_2); }, null); }
export function View_FileListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "file-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileListComponent_1)), i1.ɵdid(2, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.inputFiles && (_co.inputFiles.length > 0)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_FileListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "file-list", [], null, null, null, View_FileListComponent_0, RenderType_FileListComponent)), i1.ɵdid(1, 49152, null, 0, i10.FileListComponent, [], null, null)], null, null); }
var FileListComponentNgFactory = i1.ɵccf("file-list", i10.FileListComponent, View_FileListComponent_Host_0, { inputFiles: "inputFiles", addButton: "addButton", hideActionButton: "hideActionButton" }, { actionButtonClicked: "actionButtonClicked" }, []);
export { FileListComponentNgFactory as FileListComponentNgFactory };
