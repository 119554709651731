/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-group.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./form-group.component";
var styles_FormGroupComponent = [i0.styles];
var RenderType_FormGroupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormGroupComponent, data: {} });
export { RenderType_FormGroupComponent as RenderType_FormGroupComponent };
function View_FormGroupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 1, 0, currVal_0); }); }
function View_FormGroupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "small", [["class", "form-text text-muted"], ["id", "helpText"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.helpText; _ck(_v, 1, 0, currVal_0); }); }
export function View_FormGroupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormGroupComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormGroupComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.helpText; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_FormGroupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-form-group", [], null, null, null, View_FormGroupComponent_0, RenderType_FormGroupComponent)), i1.ɵdid(1, 49152, null, 0, i3.FormGroupComponent, [], null, null)], null, null); }
var FormGroupComponentNgFactory = i1.ɵccf("app-form-group", i3.FormGroupComponent, View_FormGroupComponent_Host_0, { helpText: "helpText", label: "label" }, {}, ["*"]);
export { FormGroupComponentNgFactory as FormGroupComponentNgFactory };
