import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Worker } from '../../../../services/sms';
import { Injectable } from '@angular/core';

export interface WorkerState extends EntityState<Worker>, ActiveState {
}

export const initialState: WorkerState = {
  active: null,
};

@Injectable({
  providedIn: 'root'
})
@StoreConfig({
  name: 'worker',
  idKey: 'ID',
  resettable: true,
})
export class WorkerStore extends EntityStore<WorkerState, Worker> {
  constructor() {
    super(initialState);
  }
}
