import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  ViewChild
} from '@angular/core';
import { IMAGEVIEWER_CONFIG, ImageViewerConfig, createButtonConfig } from '@hallysonh/ngx-imageviewer';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { AxiosPromise } from 'axios';

const MY_IMAGEVIEWER_CONFIG: ImageViewerConfig = {
  width: 600,
  height: 800,
  loadingMessage: ' ',
  buttonStyle: {
    iconFontFamily: 'fontello', // font used to render the button icons
    alpha: 0.5, // buttons' transparence value
    hoverAlpha: 0.7, // buttons' transparence value when mouse is over
    bgStyle: '#000000', //  buttons' background style
    iconStyle: '#ffffff', // buttons' icon colors
    borderStyle: '#000000', // buttons' border style
    borderWidth: 0 // buttons' border width (0 == disabled)
  },
  bgStyle: 'transparent',
  zoomOutButton: createButtonConfig(String.fromCharCode(0xf010), 'Zoom out', 0),
  zoomInButton: createButtonConfig(String.fromCharCode(0xf00e), 'Zoom in', 1),
  rotateLeftButton: createButtonConfig(String.fromCharCode(0xf0e2), 'Rotate left', 2),
  rotateRightButton: createButtonConfig(String.fromCharCode(0xf01e), 'Rotate right', 3),
  resetButton: createButtonConfig(String.fromCharCode(0xf0b2), 'Reset zoom', 4)
};

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  providers: [
    {
      provide: IMAGEVIEWER_CONFIG,
      useValue: MY_IMAGEVIEWER_CONFIG
    }
  ]
})
export class FileViewerComponent implements OnChanges {
  @ViewChild('fileContainer', { static: false }) fileContainer: ElementRef;
  @ViewChild('imageViewer', { static: false }) imageViewer: any;
  @ViewChild('pdfView', { static: false }) pdfView: PdfJsViewerComponent;
  @Input() filename: string;
  @Input() httpReq: () => AxiosPromise<any>;
  @Input() id: string;
  @Input() imgHeight: number;
  @Input() showDownload: boolean;
  currentID = '';

  isPDF = false;
  loading = true;
  unknownType = false;
  blob: Blob;
  blobUrl = '';

  downloadFile() {
    const link = document.createElement('a');
    link.download = this.filename;
    link.href = this.blobUrl;
    link.style.display = 'none';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async ngOnChanges() {
    if (this.id === this.currentID) {
      return;
    }
    this.currentID = this.id;

    this.loading = true;

    const r = await this.httpReq();
    const contentType = r.headers['content-type'];
    this.blob = r.data;
    this.blobUrl = URL.createObjectURL(this.blob);

    this.unknownType = false;
    this.isPDF = /application\/((x|apple)-)?pdf/.test(contentType);
    if (!this.isPDF) {
      this.unknownType = !/^image/.test(contentType);
    }

    this.loading = false;
  }
}
