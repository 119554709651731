import { QueryEntity } from '@datorama/akita';
import { UserState, UserStore } from './user.store';
import { Injectable } from '@angular/core';
import { User } from '../../../services/sms';
import { map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { userHasRole, UserRole } from '../user.util';
import { } from 'rxjs/Subscription';

@Injectable({
  providedIn: 'root'
})
export class UserQuery extends QueryEntity<UserState, User> {
  isAuthenticated$ = this.selectCount().pipe(map(c => Boolean(c > 0)));
  isNotAuthenticated$ = this.isAuthenticated$.pipe(map(b => !b));
  activeEmail$ = this.selectActive(user => user.Email);
  activeUser$: Observable<User> = this.selectActive();

  waitForUser(): Promise<User> {
    return new Promise((res) => {
      const unsubscribe: Subject<void> = new Subject();
      const activeUser$ = this.selectActive();
      activeUser$
        .pipe(takeUntil(unsubscribe))
        .subscribe(user => {
          unsubscribe.next();
          unsubscribe.complete();
          res(user);
        });
    });
  }

  hasRole$ = (userRole: UserRole) => this.selectActive(user => userHasRole(user, userRole));

  constructor(protected store: UserStore) {
    super(store);
  }
}
