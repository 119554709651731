import { Component, Output, ViewChild, EventEmitter } from '@angular/core';

@Component({
  selector: 'file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.css']
})
export class FileUploaderComponent {
  @Output()
  files = new EventEmitter<File[]>();

  @ViewChild('fileInput', { static: true })
  input;

  openFileSelector() {
    this.input.nativeElement.click();
  }

  publish() {
    const files = [] as File[];
    for (const file of this.input.nativeElement.files) {
      files.push(file);
    }
    this.files.emit(files);
  }

  onDrop(event) {
    event.preventDefault();
    this.input.nativeElement.files = event.dataTransfer.files;
    this.publish();
  }
  onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
  }
}
